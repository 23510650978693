body {
  background-color: #060607;
  width: 100vw;
  margin: 0;
  padding: 0;
  font-size: 14px;
  overflow: auto;
  overflow-x: hidden !important;
}

::-webkit-scrollbar {
  width: 1.2em;
}

::-webkit-scrollbar-track {
  background: #1a1631;
}

::-webkit-scrollbar-thumb {
  background: #433d6980;
  border: .2em solid #1a1631;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #433d69cc;
}

::-webkit-scrollbar-thumb:active {
  background: #433d69;
}

a {
  color: inherit;
  pointer-events: auto;
  text-decoration: none;
}

ul {
  margin: 0;
}

canvas {
  background-color: #0000 !important;
}

.button {
  color: #b6abff;
  pointer-events: auto;
  background-color: #0000;
  border: 1px solid #5346a5;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .6em;
  padding: .6em .9em;
  font-family: Roboto;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all .15s;
  display: inline-flex;
}

#contact-form button {
  color: #ffffffde;
  pointer-events: auto;
  cursor: pointer;
  background-color: #5346a5;
  border: 1px solid #5346a5;
  border-radius: 0;
  justify-content: center;
  align-items: center;
  gap: .6em;
  padding: .6em .9em;
  font-family: Roboto;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: all .15s;
  display: inline-flex;
}

#contact-form button:hover {
  color: #ffffffde;
  background-color: #5346a5;
  border: 1px solid #5346a5;
  transition: all .15s;
}

.button img {
  width: 1em;
}

.button:hover {
  color: #ffffffde;
  background-color: #5346a5;
  border: 1px solid #5346a5;
  transition: all .15s;
}

#artboard {
  z-index: 10;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  bottom: 0;
  left: 50vw;
  transform: translateX(-50%);
}

@keyframes sizeChange {
  0%, 100% {
    width: 52px;
    height: 52px;
  }

  50% {
    width: 60px;
    height: 60px;
  }
}

.start-button-fade-in {
  transition: all .5s;
  opacity: 1 !important;
}

#start-button-container {
  z-index: 500;
  opacity: 0;
  width: 50px;
  height: 50px;
  margin: 0 0 3vh;
  transition: all .5s;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);
}

#start-button {
  pointer-events: auto;
  background-color: #4aff396c;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: 1.3s infinite sizeChange;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#start-button div {
  background-color: #49ff39;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

#loader {
  z-index: 200;
  opacity: 1;
  flex-direction: column;
  gap: .5em;
  width: 10vw;
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.loader-fade-out {
  transition: all .3s;
  opacity: 0 !important;
  width: 0 !important;
}

#loading-bar-container {
  background-color: #1a1631;
  border-radius: .2em;
  width: 100%;
  height: .4em;
}

#loading-text {
  color: #5346a5;
  text-align: center;
  opacity: .5;
  width: 100%;
  font-family: Montserrat;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#loading-bar {
  background: linear-gradient(90deg, #6e59fc 0%, #a89bff 102.8%);
  border-radius: .2em;
  width: 0%;
  height: 100%;
  box-shadow: 0 0 12px #7f6fe980;
}

.div-fade-in {
  transition-property: transform, opacity;
  transition-duration: .2s;
  transition-timing-function: ease-out;
  transform: translateY(50px);
  opacity: 0 !important;
}

.div-fade-out {
  transition-property: transform, opacity;
  transition-duration: .4s;
  transition-timing-function: ease-out;
  opacity: 0 !important;
}

.artboard-fade-out-mobile {
  transform-origin: 0 100%;
  transition-property: transform, opacity, scale;
  transition-duration: .4s;
  transition-timing-function: ease-out;
  scale: .98;
  opacity: 0 !important;
}

.artboard-fade-in-mobile {
  transform-origin: 0 100%;
  transition-property: transform, opacity, scale;
  transition-duration: .4s;
  transition-timing-function: ease-out;
  scale: 1;
  opacity: 1 !important;
}

.div-in-view {
  opacity: 1;
  transition-property: transform, opacity;
  transition-duration: .5s;
  transition-timing-function: ease-out;
  transform: none;
}

.central-left-div-load-animation, .central-right-div-load-animation {
  transition: all 1.1s;
  opacity: 1 !important;
  transform: translateY(0) !important;
}

.bg-banner-load-animation {
  transition: all 2.2s;
  opacity: .12 !important;
}

.nav-load-animation {
  transition: all 1.3s;
  opacity: 1 !important;
}

.background-load-animation {
  transition: all .5s;
  opacity: 1 !important;
}

#backgrounds {
  opacity: 0;
}

#project-purple-background {
  z-index: 20;
  pointer-events: none;
  opacity: 0;
  background-color: #0e0321;
  width: 100vw;
  height: 100vh;
  transition: opacity .5s;
  position: fixed;
}

.purple-background-fadein {
  transition: opacity .5s;
  opacity: 1 !important;
}

#backgrounds {
  width: 100vw;
  height: 100vh;
  position: fixed;
}

#purple-bg {
  z-index: 2;
  background: radial-gradient(circle, #151518, #030304);
  width: 100%;
  height: 100%;
  position: absolute;
}

#purple-bg-1 {
  opacity: .4;
  mix-blend-mode: overlay;
  z-index: 4;
  background: #5b32b1;
  width: 100%;
  height: 100%;
  position: absolute;
}

#purple-bg-2 {
  opacity: .3;
  z-index: 3;
  background: radial-gradient(50% 50%, #383446 0%, #06001f 100%);
  width: 100%;
  height: 100%;
  position: absolute;
}

#top-nav {
  z-index: 100;
  box-sizing: border-box;
  pointer-events: none;
  opacity: 0;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 0 0 3.42em;
  display: flex;
  position: fixed;
  top: 0;
}

#top-nav-hamburger {
  display: none;
}

#bottom-nav {
  box-sizing: border-box;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  justify-content: flex-end;
  align-items: center;
  gap: 2.28em;
  width: 100%;
  padding: 0 3.42em 2.28em 0;
  display: flex;
  position: fixed;
  bottom: 0;
  right: 0;
}

#bottom-nav svg {
  filter: invert();
  opacity: .6;
  width: 1.88em;
  transition: opacity .15s;
}

#bottom-nav .icon2 svg, #bottom-nav .icon3 svg {
  width: 1.3em;
}

#bottom-nav svg:hover {
  opacity: 1;
  transition: opacity .15s;
}

#website-logo {
  width: 2.14em;
  margin: 2.57em 0 0;
}

#nav-right-panel {
  box-sizing: border-box;
  flex-direction: column;
  gap: 2vh;
  width: 7.71em;
  padding: 1.71em 0 0;
  list-style-type: none;
  display: flex;
}

#nav-right-panel img {
  display: none;
}

.nav-module {
  color: #ffffff5c;
  box-sizing: border-box;
  width: 100%;
  font-family: Montserrat;
  font-size: 1.14em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  transition: all .15s;
  display: block;
}

.nav-module:hover:not(.active) {
  color: #b6abff;
  border-right: 6px solid #ffffff1a;
  transition: color .15s;
}

.active {
  color: #b6abff;
  border-right: 6px solid #b6abff;
  transition: color .15s;
}

#central-div-1 {
  z-index: 9;
  box-sizing: border-box;
  color: #fff;
  justify-content: space-between;
  width: 100vw;
  padding: 25vh 10vw 0;
  font-family: Montserrat;
  line-height: normal;
  display: flex;
  position: relative;
}

.central-left-div {
  font-weight: 700;
}

#central-div-1 .central-left-div {
  opacity: 0;
  transform: translateY(2em);
}

#central-div-1 .central-left-div div:nth-of-type(2) {
  opacity: .9;
  margin: 4vh 0 8vh;
  font-size: 1.42em;
}

#name-div img {
  width: 28em;
  margin: 0 0 0 -2em;
}

#central-div-1 .central-left-div div:nth-of-type(2) span:first-of-type {
  color: #ffffff5c;
}

.central-left-div div:nth-of-type(2) span:nth-of-type(2) {
  color: #ffffffde;
}

.central-right-div {
  opacity: .9;
  color: #ffffff5c;
  flex-direction: column;
  align-items: flex-start;
  width: 10em;
  margin: 2.8em 0 0;
  font-size: 2em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
}

#central-div-1 .central-right-div {
  opacity: 0;
  gap: 3.5vh;
  transform: translateY(2em);
}

#central-div-1 .central-right-div div:nth-of-type(2) {
  color: #ffffff5c;
  align-items: center;
  gap: .57em;
  font-family: Roboto;
  font-size: .6em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

#central-div-1 .central-right-div div:nth-of-type(2) img {
  width: .6em;
}

#central-div-1 .central-right-div div:first-of-type span {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(97deg, #7c68ff 11.65%, #a698ff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  font-family: Montserrat;
  font-size: 1em;
  font-style: italic;
  font-weight: 700;
  line-height: normal;
}

#bg-banner-1 {
  -webkit-text-fill-color: transparent;
  z-index: 5;
  opacity: .12;
  box-sizing: border-box;
  pointer-events: none;
  opacity: 0;
  background: radial-gradient(50% 50%, #ffffff5c 0%, #ffffff1c 100%);
  -webkit-background-clip: text;
  background-clip: text;
  justify-content: space-between;
  width: 90%;
  margin: 0 4vw 0 -3vw;
  font-family: Montserrat;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  display: flex;
  position: absolute;
  top: 50vh;
}

#bg-banner-2 {
  color: #ffffff5c;
  z-index: 5;
  opacity: .12;
  box-sizing: border-box;
  pointer-events: none;
  width: 100%;
  padding: 0 0 0 2vw;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 45%;
}

#bg-banner-3 {
  color: #ffffff5c;
  z-index: 5;
  opacity: .12;
  box-sizing: border-box;
  pointer-events: none;
  width: 100%;
  padding: 0 0 0 2vw;
  font-size: 14vw;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  position: absolute;
  top: 15%;
}

#central-div-2 {
  z-index: 9;
  box-sizing: border-box;
  color: #fff;
  justify-content: space-between;
  width: 100vw;
  padding: 40vh 10vw 10vh;
  font-family: Montserrat;
  font-style: normal;
  line-height: normal;
  display: flex;
  position: relative;
}

.central-left-div .title img {
  width: 28em;
}

#central-div-2 .central-left-div {
  flex-direction: column;
  align-items: flex-start;
  gap: 5vh;
  display: flex;
}

.work-ex-selector {
  opacity: .8;
  cursor: pointer;
  flex-direction: column;
  gap: .57em;
  width: 100%;
  transition: all .15s;
  display: flex;
}

.work-ex-selector div:first-of-type {
  color: #ffffff5c;
  pointer-events: none;
  font-size: 1.42em;
  font-weight: 700;
}

.work-ex-selector div:nth-of-type(2) {
  color: #ffffff5c;
  pointer-events: none;
  flex-direction: row;
  align-items: center;
  gap: .57em;
  font-size: 1.14em;
  font-weight: 400;
  display: flex;
}

.work-ex-selector div:nth-of-type(2) span:first-of-type, .work-ex-selector div:nth-of-type(2) span:nth-of-type(2) {
  color: #ffffff5c;
}

.work-ex-active {
  opacity: 1;
  box-shadow: -23.5em 0 #050310b3, -22.5em 0 #b6abff4d;
}

.work-ex-active div:first-of-type {
  color: #b6abff;
}

.work-ex-active div:nth-of-type(2), .work-ex-active div:nth-of-type(2) span:first-of-type {
  color: #ffffffde;
}

.work-ex-active div:nth-of-type(2) span:nth-of-type(2) {
  color: #ffffff8c;
}

.work-ex-selector:hover:not(.work-ex-active) {
  opacity: 1;
}

.work-ex-selector:hover:not(.work-ex-active) div:first-of-type {
  color: #ffffffa6;
}

.work-ex-active:hover:not(.work-ex-active) div:nth-of-type(2), .work-ex-selector:hover:not(.work-ex-active) div:nth-of-type(2) span:first-of-type, .work-ex-selector:hover:not(.work-ex-active) div:nth-of-type(2) span:nth-of-type(2) {
  color: #ffffff8c;
}

#central-div-2 .central-right-div {
  width: 10em;
  margin: .8em 0 0;
  font-family: Montserrat;
  opacity: 1 !important;
}

#central-div-2 .div-right-nos {
  justify-content: space-between;
  align-items: flex-end;
  width: 10em;
  margin: 0 0 4vh;
  display: flex;
}

#central-div-2 .div-right-nos div {
  flex-direction: column;
  display: flex;
}

#central-div-2 .div-right-nos div span:first-of-type {
  -webkit-text-fill-color: transparent;
  background: linear-gradient(161deg, #7b67ff 1.93%, #7d6de273 86.71%);
  -webkit-background-clip: text;
  background-clip: text;
  font-size: 1.5em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#central-div-2 .div-right-nos div span:nth-of-type(2) {
  color: #b6abff;
  font-size: .71em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

#central-div-2 .div-right-desc {
  color: #ffffff5c;
  flex-direction: column;
  align-items: flex-start;
  gap: 3.5vh;
  font-size: .57em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}

#central-div-2 .div-right-desc .desc-white {
  color: #ffffffde;
}

#central-div-2 .div-right-desc a {
  color: #b6abff;
  align-items: center;
  gap: .56em;
  font-family: Roboto;
  font-size: .9em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: gap .15s;
  display: flex;
}

#central-div-2 .div-right-desc a img {
  width: 1em;
}

#central-div-2 .div-right-desc a:hover {
  color: #b6abff;
  gap: 1em;
}

#work-ex-tata, #work-ex-idc, #work-ex-spa {
  display: none;
}

#central-div-3 {
  z-index: 9;
  box-sizing: border-box;
  color: #fff;
  width: 36.3em;
  margin: 0 10vw 4vh 18vw;
  font-family: Montserrat;
  line-height: normal;
  position: relative;
  overflow: visible;
}

#central-div-3 .central-left-div, #work-exs {
  flex-direction: column;
  gap: 5vh;
  display: flex;
}

#skills-div {
  flex-direction: column;
  gap: 3.8vh;
  display: flex;
}

#skills-div .skill-div {
  flex-direction: column;
  gap: 1.8vh;
  display: flex;
}

#skills-div .skill-div .skill-div-heading {
  color: #7d6de2;
  font-family: Montserrat;
  font-size: 1.42em;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

#skills-div .skill-div .skill-div-content {
  color: #ffffffde;
  flex-wrap: wrap;
  gap: .57em .85em;
  font-family: Montserrat;
  font-size: 1.14em;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
}

#skills-div .skill-div .skill-div-icons {
  align-items: center;
  gap: 1.14em;
  display: flex;
}

#skills-div .skill-div .skill-div-icons img {
  opacity: .6;
  -webkit-filter: grayscale();
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  width: 1.57em;
  transition: all .15s;
}

#skills-div .skill-div .skill-div-icons img:hover {
  opacity: 1;
  -webkit-filter: grayscale(0%);
  -moz-filter: grayscale(0%);
  -ms-filter: grayscale(0%);
  -o-filter: grayscale(0%);
  width: 1.57em;
  transition: all .15s;
}

#project-container {
  z-index: 20;
  color: #fff;
  flex-direction: column;
  gap: 8vh;
  width: 100vw;
  padding: 7em 0 2.28em;
  display: flex;
  position: relative;
}

.projects-width {
  border-bottom: 1px solid #ffffff2e;
  flex-direction: column;
  gap: 7vh;
  width: 62em;
  margin: 0 auto;
  padding: 0 0 8vh;
  display: flex;
}

.projects-header {
  color: #5346a5;
  box-sizing: border-box;
  width: 100%;
  font-family: Montserrat;
  font-size: 2.5em;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

#work-projects-svg {
  width: 10em;
}

.project {
  cursor: pointer;
  border-radius: 1.6em;
  outline: 1em solid #10072900;
  flex-direction: row;
  align-items: center;
  gap: 5.14em;
  width: 100%;
  margin: 0 auto;
  transition: background-color .2s, outline .2s, transform .5s, opacity .5s;
  display: flex;
  position: relative;
}

.project:hover {
  background-color: #140b2da9;
  outline: 1em solid #140b2d8a;
  transition: background-color .2s, outline .2s, transform .5s, opacity .5s;
}

.project:nth-of-type(odd) {
  flex-direction: row-reverse;
}

.project-image {
  border-radius: 1.6em;
  width: 35.71em;
  height: auto;
}

.project-info {
  flex-direction: column;
  gap: 1.71em;
  display: flex;
}

.project-links {
  gap: 1em;
  display: flex;
}

.project:nth-of-type(odd) .project-info {
  align-items: flex-end;
}

.project-title {
  color: #ffffffde;
  font-family: Montserrat;
  font-size: 2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.project:nth-of-type(odd) .project-title {
  text-align: right;
}

.project-desc {
  color: #ffffff8c;
  font-family: Roboto;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.project:nth-of-type(odd) .project-desc {
  text-align: right;
}

#project-container a {
  color: #b6abff;
  align-items: center;
  gap: .56em;
  font-family: Roboto;
  font-size: .9em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  transition: gap .15s;
  display: flex;
}

#project-container a img {
  width: 1em;
}

.project-white-text {
  color: #ffffffde;
}

#project-container .project-corners {
  pointer-events: none;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  transition: all .2s cubic-bezier(.69, -.49, .42, 1.4);
  display: flex;
  position: absolute;
  right: 0;
}

#project-container .project:nth-of-type(odd) .project-corners {
  left: 0;
}

#project-container .project:nth-of-type(odd) .project-corners img {
  transform: scaleX(-1);
}

#project-container .project-corners img {
  width: 1em;
}

.top-corner, .bottom-corner {
  transition: all .2s cubic-bezier(.69, -.49, .42, 1.4);
}

.project:nth-of-type(2n):hover .project-corners {
  margin-right: -5px;
}

.project:nth-of-type(odd):hover .project-corners {
  margin-left: -5px;
}

.project:nth-of-type(2n):hover .project-corners .top-corner {
  margin-top: -5px;
  transition: all .2s cubic-bezier(.69, -.49, .42, 1.4);
}

.project:nth-of-type(2n):hover .project-corners .bottom-corner {
  margin-bottom: -5px;
  transition: all .2s cubic-bezier(.69, -.49, .42, 1.4);
}

.project:nth-of-type(odd):hover .project-corners .top-corner {
  margin-top: -5px;
  transition: all .2s cubic-bezier(.69, -.49, .42, 1.4);
}

.project:nth-of-type(odd):hover .project-corners .bottom-corner {
  margin-bottom: -5px;
  transition: all .2s cubic-bezier(.69, -.49, .42, 1.4);
}

#also-check {
  justify-content: space-between;
  width: 100%;
  margin: 0 auto;
  display: flex;
  position: relative;
}

.also-check-child {
  cursor: pointer;
  border-radius: 1.6em;
  outline: 1em solid #10072900;
  flex-direction: column;
  gap: 1.71em;
  width: 30em;
  padding: 0 0 1.6em;
  transition: background-color .2s, outline .2s, transform .5s, opacity .5s;
  display: flex;
}

#also-check-out-anchor #also-check-out-waititshere-a {
  cursor: pointer;
  border-radius: 1.6em;
  outline: 1em solid #10072900;
  flex-direction: column;
  gap: 1.71em;
  width: 30em;
  padding: 0 0 1.6em;
  font-size: 1em;
  transition: background-color .2s, outline .2s, transform .5s, opacity .5s;
  display: flex;
}

#also-check-out-waititshere-a #waititshere-banner {
  width: 100%;
}

#also-check-out-waititshere-a #waititshere-external-icon {
  width: .8em;
}

.also-check-child:hover {
  background-color: #140b2d8a;
  outline: 1em solid #140b2d8a;
  transition: background-color .2s, outline .2s, transform .5s, opacity .5s;
}

.also-check-child .also-check-card {
  box-sizing: border-box;
  flex-direction: column;
  gap: .42em;
  width: 100%;
  display: flex;
}

.also-check-child .also-check-title {
  color: #ffffffde;
  font-family: Montserrat;
  font-size: 2em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.also-check-child .also-check-title img {
  width: .6em;
}

.also-check-child .also-check-content {
  color: #ffffff8c;
  font-family: Roboto;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.also-check-child img {
  border-radius: 1.6em;
}

#footer {
  text-align: center;
  color: #ffffff8c;
  box-sizing: border-box;
  height: 1.7em;
  font-family: Montserrat;
  font-size: 1.14em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.projects-width:nth-of-type(3) {
  box-sizing: border-box;
  border-bottom: none;
  width: 30em;
  padding: 0 0 4vh;
}

#contact-form {
  flex-direction: column;
  gap: 1.42em;
  display: flex;
}

#contact-form .form-row {
  flex-direction: column;
  gap: .57em;
  display: flex;
}

#contact-form .form-label {
  color: #ffffff8c;
  font-family: Roboto;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

#contact-form .form-field {
  color: #ffffffde;
  box-sizing: border-box;
  background: none;
  border: 1px solid #ffffff5c;
  border-radius: 0;
  padding: .57em .6em;
  font-family: Roboto;
  font-size: 1em;
  transition: border 75ms;
}

#contact-form .form-field:hover {
  border: 1px solid #ffffffde;
}

#contact-form .form-field:focus {
  border: 1px solid #b6abff;
  outline: none !important;
}

#contact-form .form-text-area {
  resize: vertical;
  color: #ffffffde;
  background: none;
  border: 1px solid #ffffff5c;
  border-radius: 0;
  min-height: 12em;
  padding: .57em .6em;
  font-family: Roboto;
  font-size: 1em;
  transition: border 75ms;
}

#contact-form .form-text-area:hover {
  border: 1px solid #ffffffde;
}

#contact-form .form-text-area:focus {
  border: 1px solid #b6abff;
  outline: none !important;
}

#contact-form .form-buttons {
  justify-content: space-between;
  width: 100%;
  transition: all .15s;
  display: flex;
}

#contact-form .form-buttons .button {
  transition: all .15s;
}

#contact-form .form-buttons .button:nth-of-type(2) {
  color: #ffffffde;
  background: #5346a5;
}

#contact-form .form-buttons .button:hover {
  color: #ffffffde;
  background-color: #5346a5;
  border: 1px solid #5346a5;
  transition: all .15s;
}

#about-nav-anchor {
  opacity: 0;
  width: 10vw;
  height: 100px;
  position: absolute;
  top: 28vh;
}

.light-purple {
  color: #7d6de2;
}

#back-to-top {
  color: #b6abff;
  justify-content: center;
  align-items: center;
  gap: .56em;
  font-family: Roboto;
  font-size: 1em;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  display: flex;
}

#back-to-top img {
  transition: all .2s ease-in-out;
}

#back-to-top:hover img {
  transition: all .1s ease-in-out;
  transform: translateY(-.2em);
}

#grainy-svg {
  z-index: 10;
  mix-blend-mode: normal;
  opacity: .1;
  pointer-events: none;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
}

.nav-right-panel-hidden {
  opacity: 1;
  transform: translateX(0);
}

dialog::-webkit-scrollbar {
  width: 0;
}

.project-dialog-close-icon {
  color: inherit;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  background: none;
  border: none;
  padding: 0;
}

dialog::backdrop {
  opacity: .75;
  background-color: #000;
  -webkit-animation: none;
}

dialog {
  color: #fffc;
  background: none;
  border: none;
  border-top-left-radius: 1.2em;
  border-top-right-radius: 1.2em;
  width: 60vw;
  max-height: 100vh;
  margin-top: 3vh;
  margin-bottom: 10vh;
  padding: 0;
  overflow-x: hidden;
}

dialog:focus {
  outline: none;
}

dialog[open] {
  -webkit-animation: .3s ease-out show;
}

@-webkit-keyframes show {
  from {
    opacity: 0;
    transform: translateY(2.5%);
  }

  to {
    opacity: 1;
    transform: translateY(0%);
  }
}

dialog[open]::backdrop {
  -webkit-animation: .2s ease-out show-backdrop;
}

dialog.hide::backdrop {
  -webkit-animation: .2s ease-out hide-backdrop;
}

@-webkit-keyframes show-backdrop {
  from {
    opacity: 0;
  }

  to {
    opacity: .75;
  }
}

@-webkit-keyframes hide-backdrop {
  to {
    opacity: 0;
  }
}

dialog.hide {
  -webkit-animation: .3s ease-out hide;
}

@-webkit-keyframes hide {
  to {
    opacity: 0;
    transform: translateY(2.5%);
  }
}

.project-dialog-close-icon {
  opacity: .75;
  transition: all .1s;
}

.project-dialog-close-icon:hover {
  opacity: 1;
  transition: all .1s;
}

body.dialog-open {
  overflow: hidden;
  margin-right: 15px !important;
}

.dialog-container-div {
  flex-direction: column;
  gap: 0;
  display: flex;
}

.dialog-content {
  z-index: 200;
  background-color: #0f1122;
  border-bottom-right-radius: 1.2em;
  border-bottom-left-radius: 1.2em;
  flex-direction: column;
  flex: auto;
  gap: 2.28em;
  margin: 0 0 8vh;
  padding: 2.28em;
  display: flex;
}

.project-dialog-top {
  box-sizing: border-box;
  z-index: 250;
  background-color: #0f1122;
  flex: none;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4em;
  width: 100%;
  padding: 2.28em 2.28em 0;
  display: flex;
  position: sticky;
  top: 0;
  overflow: hidden;
}

.project-dialog-header {
  flex-direction: column;
  gap: .57em;
  display: flex;
}

.project-dialog-bannerimage {
  border-radius: 16px;
}

.project-dialog-bannerimage img {
  border-radius: 16px;
  width: 100%;
  height: auto;
}

.project-dialog-title {
  font-family: Montserrat;
  font-size: 1.8em;
  transition: all .2s;
}

.project-dialog-subtitle {
  align-items: flex-end;
  gap: .85em;
  font-family: Roboto;
  font-size: 1.14em;
  transition: all .2s;
  display: flex;
}

.project-dialog-subtitle a {
  color: #b6abff;
  align-items: flex-end;
  gap: .28em;
  display: flex;
}

.project-dialog-subtitle a img {
  width: 1em;
  height: auto;
}

.project-dialog-subtitle img {
  width: auto;
  height: 1em;
}

.project-dialog-subtitle span {
  color: #ffffff5c;
}

.project-dialog-section {
  flex-direction: column;
  gap: 1.14em;
  font-family: Roboto;
  display: flex;
}

.project-container-section-divider {
  background-color: #ffffff1f;
  width: 100%;
  height: 1px;
  min-height: 1px;
}

.project-dialog-section-content {
  padding: 0 0 0 28px;
}

.project-dialog-section-header {
  border-left: 12px solid #7d6de2;
  padding: 0 0 0 16px;
  font-family: Montserrat;
  font-size: 1.42em;
  font-weight: 700;
}

.project-dialog-section-subheader {
  border-left: 12px solid #7d6de2;
  margin-top: .5em;
  padding: 0 0 0 16px;
  font-family: Montserrat;
  font-size: 1.14em;
  font-weight: 700;
}

.project-dialog-section-tertiary-header {
  margin-top: .5em;
  padding: 0 0 0 28px;
  font-family: Montserrat;
  font-size: 1.14em;
  font-weight: 700;
}

.project-dialog-section-header-support {
  color: #ffffff5c;
  margin-top: -.7em;
  padding: 0 0 0 28px;
  font-style: italic;
}

.project-dialog-content-cards {
  gap: 1em;
  margin: 0 0 .75em;
  font-size: .9em;
  display: flex;
}

.card {
  background-color: #171a33;
  border-radius: 1em;
  flex-direction: column;
  gap: 1.5em;
  width: 100%;
  padding: 1.25em;
  display: flex;
}

#spacetime-audience img {
  width: 3.5em;
  height: auto;
}

#spacetime-audience .card {
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 8.5em;
}

#spacetime-audience-2 span {
  color: #ffffff8c;
  font-style: italic;
}

#spacetime-audience-2 .card {
  gap: .8em;
}

.project-dialog-bullet-point {
  align-items: flex-start;
  gap: .6em;
  display: flex;
}

.project-dialog-bullet-point img {
  height: 1em;
}

.bullet-point-container {
  flex-direction: column;
  gap: .8em;
  display: flex;
}

#spacetime-litreview-cards .card {
  flex-direction: row;
  gap: 2em;
}

#spacetime-litreview-cards .card img {
  border-radius: .5em;
  width: auto;
  height: 11em;
}

.project-multiline-card-container {
  flex-direction: column;
  gap: .25em;
  display: flex;
}

.spacetime-litreview-content {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.spacetime-litreview-content div:first-child {
  font-weight: 600;
}

.spacetime-litreview-content div:nth-of-type(2) {
  color: #ffffff8c;
}

.project-dialog-section-content a {
  color: #b6abff;
  align-items: center;
  gap: .25em;
  font-family: Roboto;
  font-size: 1em;
  display: flex;
}

.project-dialog-section-content a img {
  width: auto;
  height: 1.2em;
}

#embedding-image {
  width: 14em;
  height: auto;
  margin: 1em auto;
}

.big-small-numbers {
  gap: 4em;
  display: flex;
}

.big-small-numbers div {
  flex-direction: column;
  display: flex;
}

.big-small-numbers div span:first-child {
  color: #b6abff;
  font-size: 2em;
  font-weight: 700;
}

.project-dialog-prev-next-buttons {
  color: #7d6de2;
  text-align: center;
  z-index: 150;
  justify-content: space-between;
  width: 91vw;
  font-family: Montserrat;
  font-size: 1.2em;
  font-weight: 600;
  display: flex;
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.project-dialog-prev-next-buttons img {
  width: 2.5em;
  height: auto;
}

.previous-project, .next-project {
  opacity: .5;
  cursor: pointer;
  border-radius: 1em;
  flex-direction: column;
  align-items: center;
  gap: .75em;
  width: 5em;
  padding: 1.2em;
  transition: all .2s;
  display: flex;
}

.previous-project:hover, .next-project:hover {
  opacity: .8;
  background-color: #0f1122a9;
  transition: all .2s;
}

#spacetime-dialog .previous-project, #genocorp-next-project-button {
  opacity: 0;
  pointer-events: none;
}

#omni-intro-brands {
  width: 50%;
  height: auto;
}

.omni-research-cards-left, .omni-research-cards-right {
  flex-direction: column;
  gap: .8em;
  display: flex;
}

.omni-research-cards-left {
  text-align: center;
  align-items: center;
  width: 50%;
  font-weight: 600;
}

.omni-research-cards-right {
  align-items: left;
  width: 100%;
}

.omni-research-cards-left img, .omni-research-cards-right img {
  width: 4em;
  height: auto;
}

#omni-research-cards .card {
  flex-direction: row;
  gap: 1.8em;
}

#omni-methodologies-cards {
  color: #ffffff8c;
}

#omni-methodologies-cards .card {
  gap: .8em;
}

#omni-methodologies-cards span {
  color: #fffc;
  font-weight: 600;
}

#omni-competitor-apps {
  width: 25%;
  height: auto;
}

#interview-screenshot-image {
  border-radius: .85em;
  width: 50%;
}

.project-bullets-grey-text {
  color: #ffffff8c;
}

#omni-personas-cards .card {
  gap: 1.6em;
}

.omni-persona-header {
  align-items: flex-start;
  gap: .85em;
  display: flex;
}

.omni-persona-image {
  border-radius: .6em;
  width: auto;
  height: 7em;
}

.omni-persona-header-name {
  font-size: 1.14em;
  font-weight: 700;
}

.omni-persona-grey-text {
  color: #ffffff8c;
  font-weight: 400;
}

.omni-persona-header-right {
  flex-direction: column;
  gap: .4em;
  display: flex;
}

.omni-persona-header-quality {
  margin-top: -.3em;
  font-style: italic;
}

.omni-persona-header-qualities {
  color: #ffffff8c;
  flex-wrap: wrap;
  align-items: center;
  column-gap: .4em;
  display: flex;
}

.omni-persona-body {
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  display: flex;
}

.omni-persona-body-content {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

.omni-persona-body-section ul {
  color: #ffffff8c;
  padding: 0 0 0 1.25em;
}

.omni-persona-body-brands {
  color: #ffffff5c;
  gap: .4em;
  margin: 1em 0 0;
  display: flex;
}

.omni-persona-section-header {
  font-weight: 700;
}

.omni-persona-motivations-container {
  flex-direction: column;
  gap: .4em;
  display: flex;
}

.omni-persona-charts-container {
  color: #ffffff8c;
  flex-direction: column;
  gap: .5em;
  display: flex;
}

.omni-persona-motivations-chart {
  flex-direction: column;
  gap: .15em;
  display: flex;
}

.omni-persona-motivations-chart img {
  width: 100%;
  height: auto;
}

.omni-scenarios-image-container {
  flex-wrap: wrap;
  gap: 1em;
  display: flex;
}

.omni-scenarios-image-container img {
  border-radius: 1em;
  width: calc(50% - .5em);
}

#omni-personalisation-card {
  border: 1px solid #5346a5;
}

#omni-ia-cards .card {
  color: #ffffff8c;
  flex-direction: column;
  align-items: center;
  gap: .85em;
  display: flex;
}

#omni-ia-cards img {
  width: auto;
  height: 4em;
}

.omni-ia-header {
  color: #fffc;
  font-weight: 500;
}

#omni-wireframes-image {
  border-radius: 1em;
  width: 100%;
  height: auto;
}

#iks-outcomes-cards {
  gap: 1em;
  width: calc(50% - 1em);
}

#iks-outcomes-cards .card {
  gap: .8em;
}

#iks-outcomes-cards span {
  color: #ffffff8c;
  font-style: italic;
}

#iks-research-areas-cards img {
  width: 4em;
  height: 4em;
}

#iks-research-areas-cards .card {
  text-align: center;
  align-items: center;
  gap: .85em;
}

.coach-scenario-arrow {
  width: 1.2em;
  height: 1.2em;
  margin: auto 0;
}

#coach-scenario-arrow-big {
  width: 1.8em;
  height: 1.8em;
}

#curriculum-img {
  width: 100%;
  height: auto;
  padding: 1.2em 0 0;
}

#iks-img {
  width: 80%;
  height: auto;
}

#iks-litreview-cards .card {
  flex-direction: row;
}

#iks-focussed-review-img {
  width: 48%;
}

.iks-litreview-card-header {
  color: #fffc;
  font-weight: 700;
}

#iks-focussed-litreview-cards .card {
  color: #ffffff8c;
  gap: .8em;
}

.iks-litreview-card-subheader {
  margin-top: -.5em;
  font-style: italic;
}

.iks-litreview-card-content {
  flex-direction: column;
  gap: .8em;
  display: flex;
}

#project-basic-card .card {
  text-align: center;
  align-items: center;
  gap: 1em;
}

#project-basic-card .card img {
  width: 4em;
  height: auto;
}

.project-basic-card-header {
  font-weight: 700;
}

.project-basic-card-body {
  color: #ffffff8c;
}

#lesson-plan-structure-img {
  width: 100%;
}

#lesson-plan-container {
  align-items: flex-start;
  gap: 1.5em;
  padding-bottom: 1em;
  display: flex;
}

#lesson-plan-container img {
  padding-top: .5em;
}

.lesson-plan-card {
  flex-direction: column;
  max-width: 9em;
  display: flex;
}

.lesson-plan-card span:first-of-type {
  font-family: Montserrat;
  font-size: 1.3em;
  font-weight: 700;
}

.lesson-plan-card span:nth-of-type(2) {
  color: #ffffff8c;
}

.iks-quickview-white .iks-litreview-card-content {
  color: #fffc;
}

#iks-workbooks-cards {
  gap: 1em;
  display: flex;
}

#iks-workbooks-cards .card {
  align-items: center;
  gap: 2em;
  width: auto;
  height: 100%;
}

#iks-workbooks-cards img {
  width: 70%;
  height: auto;
}

.iks-workbook-cards-column {
  flex-direction: column;
  gap: 1em;
  width: 100%;
  display: flex;
}

#iks-workbook-cards-grey-text {
  color: #ffffff8c;
  font-style: italic;
}

.image-screen {
  mix-blend-mode: screen;
}

#workbooks-grid-image {
  width: 9em !important;
}

#talamana-container {
  border: 3px solid #7d6de2;
  border-radius: .5em;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 100%;
  display: flex;
}

.talamana-column {
  text-align: center;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  display: flex;
}

.talamana-item {
  flex-direction: column;
  display: flex;
}

.talamana-header {
  font-weight: 700;
}

.talamana-subheader {
  color: #ffffff8c;
}

#iks-exposure-img {
  width: 90%;
}

#mannequin-website-img {
  border-radius: 1em;
  width: 80%;
}

#temple-fractal-img, #printed-temple, #evaluating-workbooks-img {
  width: 100%;
}

#iks-evaluating-exercises-grey {
  color: #ffffff8c;
}

#remote-brainstorming-img {
  width: 100%;
}

.remote-youtube {
  border-radius: 1em;
  height: 0;
  margin-left: 28px;
  padding-bottom: 56.25%;
  position: relative;
  overflow: hidden;
}

.remote-youtube iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

#remote-explorations-cards .card {
  gap: 1em;
}

#remote-explorations-cards .card img {
  border-radius: .6em;
  width: 100%;
}

#remote-state-of-art-img {
  width: 100%;
}

#remove-placement-words {
  color: #b6abff;
  gap: 2em;
  display: flex;
}

#hardware-backend-3d-img {
  border-radius: 1em;
  width: 100%;
}

#hardware-img-group {
  flex-direction: column;
  gap: 1em;
  display: flex;
}

#hardware-img-group img {
  border-radius: 1em;
  width: 100%;
}

#hardware-images-row {
  gap: 1em;
  width: calc(50% - .5em);
  display: flex;
}

.full-size-img {
  border-radius: 1em;
  width: 100%;
}

#songs-explorations-header-child img {
  width: 4em;
}

#remote-explorations-card .card {
  align-items: flex-start;
}

#project-basic-card-header-explorations {
  color: #5346a577;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;
  font-family: Montserrat;
  font-size: 2em;
  display: flex;
}

.songs-explorations-header-child {
  text-align: center;
  color: #fffc;
  flex-direction: column;
  align-items: center;
  gap: .8em;
  font-family: Roboto;
  font-size: .5em;
  display: flex;
}

.songs-two-in-three-cards {
  width: calc(66% - 1em);
}

.songs-litreview-cards img {
  border-radius: .6em;
  width: 100%;
}

.songs-litreview-cards .card {
  gap: 1em;
  width: 100%;
}

.songs-litreview-cards .project-basic-card-header {
  flex-direction: column;
  gap: .2em;
  display: flex;
}

.songs-litreview-cards .project-basic-card-header span:nth-of-type(2) {
  color: #ffffff8c;
  font-style: italic;
  font-weight: 400;
}

.songs-choosing-cards img {
  border-radius: .6em;
  width: 100%;
}

.songs-choosing-cards .project-basic-card-header img {
  width: auto;
  height: 100%;
}

.songs-choosing-cards .project-basic-card-header {
  gap: .5em;
  display: flex;
}

.songs-choosing-cards .project-basic-card-header div {
  flex-direction: column;
  gap: .2em;
  display: flex;
}

.songs-choosing-cards .card {
  gap: .8em;
}

.songs-choosing-cards .project-basic-card-header div span:nth-of-type(2) {
  color: #ffffff8c;
  font-weight: 400;
}

.songs-choosing-cards .project-basic-card-body {
  color: #ffffff5c;
  font-style: italic;
}

#assigning-emotions-grid-img {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: flex;
}

#assigning-emotions-grid-img img {
  mix-blend-mode: screen;
  width: 24em;
}

#converting-emotions-grid-img {
  mix-blend-mode: screen;
}

#generating-3d-models-cards img {
  width: 4em;
}

#generating-3d-models-cards .card {
  align-items: center;
  gap: .8em;
}

#generating-3d-models-cards .project-basic-card-header, #generating-3d-models-cards .project-basic-card-body {
  width: 100%;
}

.songs-3d-cards {
  flex-direction: column;
}

.songs-3d-cards .card {
  flex-direction: row;
  justify-content: space-between;
  gap: 1.25em;
}

.songs-3d-cards .card img {
  border-radius: .6em;
  width: 100%;
}

.songs-3d-cards, .songs-3d-cards .card {
  box-sizing: border-box;
}

.songs-3d-cards .card .project-basic-card-header img {
  width: auto;
  height: 100%;
}

.song-3d-card-column {
  box-sizing: border-box;
  flex-direction: column;
  gap: .8em;
  width: 20em;
  display: flex;
}

.songs-3d-cards .card .project-basic-card-header {
  flex-direction: row;
  align-items: center;
  gap: .5em;
  display: flex;
}

.songs-3d-cards .card .project-basic-card-header div {
  flex-direction: column;
  gap: .2em;
  display: flex;
}

.songs-3d-cards .card .project-basic-card-header div span:nth-of-type(2) {
  color: #ffffff8c;
  font-weight: 400;
}

.songs-3d-cards .project-basic-card-body {
  color: #fffc;
}

.purple-tertiary-header {
  color: #7d6de2;
}

#createnshare-persona {
  width: 18em;
}

#createnshare-persona .omni-persona-image {
  height: 6em;
}

#createnshare-dialog .createnshare-not-full-width {
  width: 60%;
}

#createnshare-ia .full-size-img {
  border-radius: 0;
  padding: 1em 0;
}

#createnshare-wireframes {
  mix-blend-mode: lighten;
}

.createnshare-prototypes .card {
  box-sizing: border-box;
  align-items: center;
}

.createnshare-prototypes .card img {
  width: 55%;
}

#bonfire-user-group {
  justify-content: center;
  width: 100%;
  display: flex;
}

#bonfire-user-group img {
  width: 45%;
}

.createnshare-features img {
  mix-blend-mode: screen;
  width: 70%;
}

.createnshare-features .card {
  box-sizing: border-box;
  flex-direction: row;
  justify-content: center;
}

#bonfire-smaller-feature-img img {
  width: 62%;
}

#guardian-image-container {
  border-radius: 1em;
  flex-direction: column;
  gap: 0;
  display: flex;
  overflow: hidden;
}

#guardian-image-container img {
  width: 100%;
}

#procedural-knowledge img {
  width: 25em;
  padding: 2em 0;
}

#coach-man img {
  mix-blend-mode: lighten;
  width: 50%;
}

.coach-mocks img {
  width: 80%;
}

.coach-mocks .card {
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  gap: 0;
  width: 100%;
}

.invisible {
  opacity: 0 !important;
}

.only-arrow {
  background: none;
}

.only-arrow img {
  width: 2em;
  height: 2em;
  margin: 0 auto;
}

.down-arrow-container {
  margin: -2.5em 0 -1.5em;
}

.coach-algorithms img {
  mix-blend-mode: lighten;
  width: 100%;
}

.coach-algorithms .card {
  padding: 2em 0;
}

.coach-comment-refinement img {
  mix-blend-mode: lighten;
  width: 50%;
}

.coach-comment-refinement .card {
  align-items: center;
  padding: 2em 0;
}

#genocorp-character-creation-image img {
  border-radius: 1em;
  width: 40%;
}

#genocorp-graph img {
  mix-blend-mode: lighten;
  width: 50%;
}

#genocorp-timeline img {
  width: 100%;
}

.genocorp-mocks img {
  width: 75%;
}

.genocorp-mocks img:first-of-type {
  margin: 0 0 -4em;
}

.genocorp-mocks .card {
  box-sizing: border-box;
  align-items: center;
}

#architecture-image-container {
  flex-direction: column;
  gap: .6em;
  display: flex;
}

#architecture-image-container img {
  border-radius: 1em;
  width: 100%;
}

@media (width <= 600px) {
  body {
    font-size: 16px;
  }

  #artboard {
    position: fixed;
  }

  #backgrounds {
    position: fixed;
    top: 0;
  }

  #loader {
    width: 7em;
  }

  #loading-bar-container {
    background-color: #1a1631;
    border-radius: .2em;
    width: 100%;
    height: .2em;
  }

  #loading-text {
    font-size: 1.4em;
  }

  #top-nav {
    background: linear-gradient(#0a0217 0%, #0e032100 100%);
    align-items: center;
    width: 100%;
    padding: 2.5em;
  }

  #top-nav-hamburger {
    pointer-events: all;
    width: 28px;
    display: block;
  }

  #nav-right-panel {
    background: linear-gradient(270deg, #090714 0%, #0a0715de 51%, #0a0815b3 75%, #0a081500 100%);
    align-items: flex-end;
    gap: 3vh;
    width: 15em;
    height: 100vh;
    padding: 2.6em 2.5em 0 0;
    font-size: 1.1em;
    transition: opacity .4s;
    position: absolute;
    top: 0;
    right: 0;
  }

  .nav-right-panel-hidden {
    opacity: 0;
    transition: opacity .4s;
    transform: translateX(100em);
  }

  .nav-module:hover:not(.active) {
    color: #ffffff5c;
    border: none;
  }

  #nav-right-panel img {
    pointer-events: all;
    z-index: 1000;
    width: 24px;
    display: block;
  }

  #nav-right-panel li:first-of-type {
    flex-direction: column;
    align-items: flex-end;
    gap: 2em;
    display: flex;
  }

  .active, .active:hover {
    border: none !important;
  }

  #website-logo {
    width: 32px;
    margin: 0;
  }

  .central-left-div {
    width: 100%;
  }

  #central-div-1 .central-left-div {
    width: 80%;
  }

  #central-div-1 {
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 80vh;
    padding: 6em 2.5em 0;
  }

  #bg-banner-1 {
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 2.5em;
    font-size: 30vw;
    display: none;
    top: 40vh;
  }

  #name-div img {
    width: 100%;
    margin: 0;
  }

  #central-div-1 .central-left-div div:nth-of-type(2) {
    text-align: center;
    margin: 1.2em 0 0;
    font-size: 1em;
  }

  #central-div-2 {
    opacity: 0;
    width: 100%;
    height: 100vh;
    padding: 2em 2.5em;
  }

  #bg-banner-2 {
    display: none;
  }

  .central-left-div .title img {
    width: 100%;
    margin: 0;
  }

  #central-div-2 .central-left-div {
    align-items: center;
    gap: 3em;
  }

  #about-nav-anchor {
    top: 0;
  }

  #work-exs {
    align-items: center;
  }

  .work-ex-selector {
    opacity: 1;
    align-items: center;
  }

  .work-ex-selector div:first-of-type {
    color: #b6abff;
    font-size: 1.2em;
  }

  .work-ex-selector div:nth-of-type(2) span:first-of-type, .work-ex-selector div:nth-of-type(2) img {
    color: #ffffffde;
  }

  .work-ex-selector div:nth-of-type(2) {
    font-size: 1em;
  }

  .work-ex-selector div:nth-of-type(2) span:nth-of-type(2) {
    color: #ffffff8c;
  }

  .work-ex-active {
    box-shadow: none;
  }

  #central-div-3 {
    width: 100%;
    height: 100vh;
    margin: 0;
    padding: 2em 2.5em;
  }

  #central-div-3 .central-left-div {
    align-items: center;
    gap: 2em;
  }

  #central-div-3 .central-left-div title {
    width: 100%;
  }

  #skills-div, #skills-div .skill-div {
    align-items: center;
    width: 100%;
  }

  #skills-div .skill-div .skill-div-heading {
    font-size: 1.2em;
  }

  #skills-div .skill-div .skill-div-content {
    justify-content: center;
    align-items: center;
    gap: .5em .9em;
    font-size: 1em;
  }

  #bg-banner-3 {
    display: none;
  }

  #project-container {
    gap: 3em;
    width: 100%;
    margin: 0;
  }

  #work-projects-svg {
    width: 10em;
    margin: 0 auto;
  }

  .project {
    gap: 1.5em;
    margin: 0;
    flex-direction: column !important;
  }

  .project-image {
    border-radius: 0;
    width: 100vw;
  }

  .project-info {
    gap: 1em;
    padding: 0 1.5em;
    align-items: flex-start !important;
  }

  .also-check-child .also-check-card {
    gap: 1em;
  }

  .projects-width {
    border: none;
    gap: 3em;
    width: 100%;
  }

  .project:nth-of-type(odd) .project-desc {
    text-align: left;
  }

  .project-title {
    font-size: 1.6em;
  }

  .project-desc {
    width: 100%;
  }

  #also-check {
    flex-direction: column;
    gap: 3em;
  }

  .projects-header {
    padding: 0 2.5rem;
    font-size: 1.6em;
  }

  .also-check-child {
    gap: 1.5em;
    width: 100%;
  }

  .also-check-child img {
    width: 100vw;
  }

  .also-check-child .card {
    gap: 1.5em;
    padding: 0 2.5em;
  }

  .also-check-child .also-check-title {
    font-size: 1.6em;
  }

  .also-check-child .also-check-content {
    width: 100%;
  }

  .also-check-child img {
    border-radius: 0;
  }

  .projects-width:nth-of-type(3) {
    gap: 2.5em;
    width: 100%;
    padding: 0 1.5em;
  }

  .projects-width:nth-of-type(3) .projects-header {
    padding: 0;
  }

  #contact-form, .form-row, .form-row input, #back-to-top {
    width: 100%;
  }

  #footer {
    width: 100%;
    padding: 0 1.5em;
    font-size: 1em;
  }

  #bottom-nav {
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 2em 0 3em;
    position: relative;
  }

  #bottom-nav .icon1 svg {
    width: 2.4em;
  }

  #bottom-nav .icon2 svg, #bottom-nav .icon3 svg {
    width: 1.8em;
  }

  #central-div-1 .central-right-div, #central-div-1 .central-left-div .button, #central-div-2 .central-right-div, .project-corners, #back-to-top {
    display: none !important;
  }

  .div-fade-out {
    transition-property: transform, opacity;
    transition-duration: .4s;
    transition-timing-function: ease-out;
    opacity: 1 !important;
  }

  dialog {
    border: none;
    border-top-left-radius: 1.2em;
    border-top-right-radius: 1.2em;
    width: 100vw;
    margin: .5em auto 0;
    overflow-x: hidden;
  }

  dialog:modal {
    max-width: 100vw;
    max-height: 100vh;
  }

  .project-dialog-title {
    font-size: 1.4em;
  }

  .project-dialog-top {
    gap: 1.5em;
    padding: 20px 20px 0;
  }

  .project-dialog-subtitle {
    gap: .5em;
    font-size: 1em;
  }

  .dialog-content {
    gap: 1.6em;
    margin: 0;
    padding: 1.6em 0 7em;
  }

  .project-dialog-section {
    gap: 1em;
    padding: 0 20px 0 0;
  }

  .project-dialog-section-content {
    padding: 0 0 0 20px;
  }

  .project-dialog-bannerimage, .project-dialog-bannerimage img {
    border-radius: 0;
  }

  .project-container-section-divider {
    width: 100%;
  }

  .project-dialog-section-header {
    border-left: 8px solid #7d6de2;
    padding: 0 0 0 12px;
    font-size: 1.2em;
  }

  .project-dialog-section-subheader {
    border-left: 8px solid #7d6de2;
    margin-top: .8em;
    padding: 0 0 0 12px;
    font-size: 1em;
  }

  .project-dialog-section-tertiary-header {
    margin-top: .2em;
    padding: 0 0 0 20px;
    font-size: 1em;
  }

  .project-dialog-section-header-support {
    margin-top: -.7em;
    padding: 0 0 0 20px;
  }

  .project-dialog-content-cards {
    flex-direction: column;
    align-items: center;
    gap: 1em;
    margin: 0 0 1em;
  }

  .card, #spacetime-audience .card {
    box-sizing: border-box;
    width: 100%;
  }

  #spacetime-litreview-cards .card {
    flex-direction: column-reverse;
    gap: 1em;
  }

  .big-small-numbers {
    gap: .8em;
  }

  .project-dialog-prev-next-buttons {
    width: 91vw;
    font-size: 1.2em;
    display: none;
    position: relative;
  }

  .previous-project, .next-project {
    opacity: 1;
    gap: .5em;
    width: 5em;
    padding: 2em 1em .5em;
  }

  .previous-project:hover, .next-project:hover {
    opacity: 1;
  }

  dialog::backdrop {
    opacity: .95;
  }

  #omni-intro-brands {
    width: 100%;
  }

  #omni-research-cards .card {
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: 1.2em;
  }

  #omni-methodologies-cards img {
    transform: rotate(90deg);
  }

  #omni-competitor-apps {
    width: 60%;
  }

  #interview-screenshot-image {
    border-radius: .4em;
    width: 100%;
  }

  .omni-scenarios-image-container {
    flex-direction: column;
    gap: 1em;
  }

  .omni-scenarios-image-container img {
    border-radius: .4em;
    width: 100%;
  }

  .project-dialog-section-content:has( > #omni-wireframes-image) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #omni-wireframes-image {
    border-radius: .4em;
    width: 50em;
    margin: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #omni-mocks) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #omni-mocks {
    width: 50em;
  }

  .project-dialog-content-cards {
    font-size: 1em;
  }

  .project-dialog-section-content a {
    padding: .5em 0;
  }

  #iks-outcomes-cards {
    box-sizing: border-box;
    width: 100%;
  }

  #iks-litreview-cards .card {
    text-align: center;
    flex-direction: column;
    align-items: center;
  }

  #lesson-plan-container {
    text-align: center;
    flex-direction: column;
    align-items: center;
    gap: .8em;
  }

  #lesson-plan-container img {
    transform: rotate(90deg);
  }

  .project-multiline-card-container {
    gap: 0;
  }

  .iks-workbook-cards-column, #iks-workbooks-cards {
    gap: .5em;
  }

  .talamana-column {
    gap: 2em;
    padding: 1em 0;
  }

  #mannequin-website-img {
    border-radius: .4em;
    width: 100%;
  }

  .project-dialog-section-content:has( > #curriculum-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #curriculum-img {
    width: 55em;
    padding: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #iks-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #iks-img {
    width: 50em;
    padding: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #iks-focussed-review-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #iks-focussed-review-img {
    width: 28em;
    padding: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #lesson-plan-structure-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #lesson-plan-structure-img {
    width: 60em;
    padding: 1em 20px 1em 0;
  }

  #procedural-knowledge {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #procedural-knowledge img {
    width: 26em;
    padding: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #iks-exposure-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #iks-exposure-img {
    width: 55em;
    padding: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #mannequin-website-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #mannequin-website-img {
    width: 40em;
    margin: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #temple-fractal-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #temple-fractal-img {
    width: 40em;
    margin: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #printed-temple) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #printed-temple {
    width: 60em;
    margin: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #evaluating-workbooks-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #evaluating-workbooks-img {
    width: 60em;
    margin: 1em 20px 1em 0;
  }

  .project-dialog-section-content:has( > #remote-brainstorming-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  #remote-brainstorming-img {
    width: 50em;
    margin: 1em 20px 1em 0;
  }

  .card {
    border-radius: .8em;
    padding: .8em;
  }

  #project-basic-card-header-explorations {
    justify-content: space-between;
    gap: 0;
  }

  .songs-explorations-header-child {
    width: 10em;
  }

  .songs-two-in-three-cards {
    box-sizing: border-box;
    width: 100%;
  }

  #remove-placement-words {
    justify-content: space-between;
  }

  #remote-explorations-cards .card {
    gap: .8em;
  }

  .project-dialog-section-content:has( > .full-size-img) {
    white-space: nowrap;
    width: 100%;
    overflow-x: auto;
  }

  .full-size-img {
    border-radius: .4em;
    width: 200%;
    margin-right: 20px;
  }

  .project-dialog-section-content:has( > #remote-state-of-art-img) {
    white-space: nowrap;
    box-sizing: border-box;
    width: 100%;
    overflow-x: auto;
  }

  #remote-state-of-art-img {
    width: 50em;
  }

  .project-dialog-section-content:has( > #hardware-backend-3d-img) {
    white-space: nowrap;
    box-sizing: content-box;
    width: 100%;
    overflow-x: auto;
  }

  #hardware-backend-3d-img {
    border-radius: .4em;
    width: 50em;
    margin-right: 20px;
  }

  #hardware-img-group img {
    border-radius: .4em;
  }

  #assigning-emotions-grid-img {
    box-sizing: border-box;
  }

  #assigning-emotions-grid-img img {
    width: 100%;
    margin: 1em 0;
  }

  #generating-3d-models-cards .card {
    text-align: center;
  }

  .songs-3d-cards .card {
    flex-direction: column;
  }

  .song-3d-card-column {
    width: 100%;
  }

  #createnshare-persona, #createnshare-dialog .createnshare-not-full-width {
    box-sizing: border-box;
    width: 100%;
  }

  .createnshare-prototypes .card img {
    width: 100%;
    margin-left: 1em;
  }

  #bonfire-dialog .createnshare-features img {
    width: 100%;
  }

  #bonfire-user-group img {
    width: 80%;
  }

  #coach-man img {
    width: 100%;
  }

  .coach-mocks img {
    width: 42em;
  }

  .coach-mocks .card {
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0 2em;
    overflow-x: auto;
  }

  .invisible {
    display: none;
  }

  .only-arrow {
    padding: 0;
  }

  .only-arrow img {
    margin: -1em auto;
    transform: rotate(0) !important;
  }

  #omni-methodologies-cards .rotate-arrow-on-mobile {
    transform: rotate(-90deg);
  }

  .coach-comment-refinement img {
    width: 100%;
  }

  #genocorp-character-creation-image img, #genocorp-graph img {
    border-radius: .4em;
    width: 100%;
  }

  .genocorp-mocks img:first-of-type {
    margin: 0;
  }

  .genocorp-mocks img {
    width: 100%;
  }

  .also-check-child .also-check-card {
    padding: 0 1.5em;
  }

  #also-check-out-anchor #also-check-out-waititshere-a {
    width: 100%;
  }

  #architecture-image-container img {
    border-radius: 0;
  }

  .remote-youtube {
    height: 20em;
    margin-left: 20px;
  }
}

@media (width >= 600px) {
  body {
    font-size: 12px;
  }

  dialog {
    width: 90vw;
  }

  dialog::backdrop {
    opacity: .95;
  }

  .project-dialog-prev-next-buttons {
    width: 89vw;
    font-size: 1.2em;
    display: none;
    position: relative;
  }

  .previous-project, .next-project {
    opacity: 1;
    gap: .5em;
    width: 5em;
    padding: 2em 1em .5em;
  }

  .previous-project:hover, .next-project:hover {
    opacity: 1;
  }
}

@media (width >= 1000px) {
  body {
    font-size: 12px;
  }

  dialog {
    width: 70vw;
  }

  .project-dialog-prev-next-buttons {
    width: 91vw;
    font-size: 1.2em;
    display: flex;
    position: fixed;
  }

  .previous-project, .next-project {
    opacity: .5;
    gap: .75em;
    width: 5em;
    padding: 1.2em;
  }

  .previous-project:hover, .next-project:hover {
    opacity: .8;
  }
}

@media (width >= 1350px) {
  body {
    font-size: 14px;
  }

  dialog {
    width: 60vw;
  }

  .project-dialog-prev-next-buttons {
    width: 91vw;
    font-size: 1.2em;
    display: flex;
    position: fixed;
  }

  .previous-project, .next-project {
    opacity: .5;
    gap: .75em;
    width: 5em;
    padding: 1.2em;
  }

  .previous-project:hover, .next-project:hover {
    opacity: .8;
  }
}

@media (width >= 1700px) {
  body {
    font-size: 16px;
  }

  dialog {
    width: 60vw;
  }

  .project-dialog-prev-next-buttons {
    width: 91vw;
    font-size: 1.2em;
    display: flex;
    position: fixed;
  }

  .previous-project, .next-project {
    opacity: .5;
    gap: .75em;
    width: 5em;
    padding: 1.2em;
  }

  .previous-project:hover, .next-project:hover {
    opacity: .8;
  }
}

@media (width >= 1850px) {
  body {
    font-size: 18px;
  }

  dialog {
    width: 60vw;
  }

  .project-dialog-prev-next-buttons {
    width: 91vw;
    font-size: 1.2em;
    display: flex;
    position: fixed;
  }

  .previous-project, .next-project {
    opacity: .5;
    gap: .75em;
    width: 5em;
    padding: 1.2em;
  }

  .previous-project:hover, .next-project:hover {
    opacity: .8;
  }
}

@media (width >= 2000px) {
  body {
    font-size: 22px;
  }

  dialog {
    width: 60vw;
  }

  .project-dialog-prev-next-buttons {
    width: 91vw;
    font-size: 1.2em;
    display: flex;
    position: fixed;
  }

  .previous-project, .next-project {
    opacity: .5;
    gap: .75em;
    width: 5em;
    padding: 1.2em;
  }

  .previous-project:hover, .next-project:hover {
    opacity: .8;
  }
}

/*# sourceMappingURL=index.677cdd51.css.map */

body {
    overflow-x: hidden !important;
    overflow: auto;
    margin:0;
    padding:0;
    background-color: #060607;
    font-size: 14px;
    width:100vw;

    /* background: rgb(2,0,36); */
}

::-webkit-scrollbar {
    width: 1.2em;               /* width of the entire scrollbar */
}
  
::-webkit-scrollbar-track {
    background: #1A1631;        /* color of the tracking area */
}
  
::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border:0.2em solid #1A1631;
    background: rgba(67, 61, 105, 0.5);
}

::-webkit-scrollbar-thumb:hover {
    background: rgba(67, 61, 105, 0.8);
}

::-webkit-scrollbar-thumb:active {
    background: rgba(67, 61, 105, 1);
}

a {
    color: inherit;
    text-decoration: none;
    pointer-events: auto;
}

ul {
    margin:0;
}

canvas {
    background-color: transparent !important;
}

.button {
    display: inline-flex;
    padding: 0.6em 0.9em;
    justify-content: center;
    align-items: center;
    gap: 0.6em;
    border-radius: 0;
    border: 1px solid #5346A5;
    background-color: transparent;
    color:#B6ABFF;
    font-family: Roboto;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: 0.15s;
    pointer-events: auto;
}

#contact-form button {
    display: inline-flex;
    padding: 0.6em 0.9em;
    justify-content: center;
    align-items: center;
    gap: 0.6em;
    border-radius: 0;
    border: 1px solid #5346A5;
    background-color: #5346A5;
    color:rgba(255, 255, 255, 0.87);
    font-family: Roboto;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition: 0.15s;
    pointer-events: auto;
    cursor: pointer;
}

#contact-form button:hover {
    border: 1px solid #5346A5;
    background-color: #5346A5;
    color:rgba(255, 255, 255, 0.87);
    transition: 0.15s;
}

.button img {
    width:1em;
}

.button:hover {
    border: 1px solid #5346A5;
    background-color: #5346A5;
    color:rgba(255, 255, 255, 0.87);
    transition: 0.15s;
}

#artboard {
    z-index:10;
    position:absolute;
    bottom:0;
    left:50vw;
    transform:translateX(-50%);
    pointer-events: none;
    opacity:0;
    position:fixed;
    /* height:50vw !important; */
}

@keyframes sizeChange {
    0%, 100% {
      width: 52px;
      height: 52px;
    }
    50% {
      width: 60px;
      height: 60px;
    }
}

.start-button-fade-in {
    opacity : 1 !important;
    transition: 0.5s;
}

#start-button-container {
    height: 50px;
    width: 50px;
    left:50%;
    bottom:0;
    margin:0 0 3vh 0;
    transform:translate(-50%);
    position: fixed;
    z-index: 500;
    transition:0.5s;
    opacity: 0;
}

#start-button {
    border-radius: 50%;
    background-color: #4aff396c;
    height:50px;
    width: 50px;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    pointer-events: auto;
    animation: sizeChange 1.3s infinite;
    position: absolute;
}

#start-button div {
    border-radius: 50%;
    background-color: #49FF39;
    height:32px;
    width: 32px;
    left:50%;
    top:50%;
    transform:translate(-50%, -50%);
    position: absolute;
}

#loader {
    width:10vw;
    z-index: 200;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%, -50%);
    display:flex;
    flex-direction: column;
    gap:0.5em;
    opacity:1;
}

.loader-fade-out {
    opacity:0 !important;
    transition:0.3s;
    width:0 !important;
}

#loading-bar-container {
    height:0.4em;
    width:100%;
    background-color: #1A1631;
    border-radius: 0.2em;
}

#loading-text {
    color: #5346A5;
    font-family: Montserrat;
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    width:100%;
    text-align: center;
    opacity: 0.5;
}

#loading-bar {
    width:0%;
    height:100%;
    border-radius: 0.2em;
    background: linear-gradient(90deg, #6E59FC 0%, #A89BFF 102.8%);
    box-shadow: 0px 0px 12px 0px rgba(127, 111, 233, 0.50);
    /* box-shadow: 0 0 4px 1px #9082f0; */
}

/* main fade in classes for divs */

.div-fade-in {
    transform: translateY(50px);
    opacity:0 !important;
    transition-property: transform, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-out;
}

.div-fade-out {
    /* transform: translateY(-50px); */
    opacity:0 !important;
    transition-property: transform, opacity;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
}

.artboard-fade-out-mobile {
    /* transform: translateY(-50px); */
    opacity:0 !important;
    transition-property: transform, opacity, scale;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
    scale:0.98;
    transform-origin: 0% 100%;
}

.artboard-fade-in-mobile {
    /* transform: translateY(-50px); */
    opacity:1 !important;
    transition-property: transform, opacity, scale;
    transition-duration: 0.4s;
    transition-timing-function: ease-out;
    transform-origin: 0% 100%;
    scale:1;
}

.div-in-view {
    transform: none;
    opacity: 1;
    transition-property: transform, opacity;
    transition-duration: 0.5s;
    transition-timing-function: ease-out;
}

/* starting div move-in */

/* #central-div-1 .central-left-div */
.central-left-div-load-animation {
    /* animation: movein 5.5s; */
    opacity:1 !important;
    transform: translateY(0) !important;
    transition: 1.1s;
}

/*#central-div-1 .central-right-div*/
.central-right-div-load-animation {
    /* animation: movein 6s; */
    opacity:1 !important;
    transform: translateY(0) !important;
    transition: 1.1s;
}

/*#bg-banner-1*/
.bg-banner-load-animation {
    /* animation: bannerfade 5.5s; */
    opacity:0.12 !important;
    transition:2.2s;
}

/* nav fade */
/*#top-nav, #bottom-nav*/
.nav-load-animation {
    /* animation: navfade 6.5s; */
    opacity:1 !important;
    transition: 1.3s;
}

/*#backgrounds*/
.background-load-animation {
    opacity: 1 !important;
    transition: 0.5s;
}

#backgrounds {
    opacity:0;
}



#project-purple-background {
    background-color:#0E0321;
    width:100vw;
    height:100vh;
    position:fixed;
    z-index: 20;
    pointer-events: none;
    opacity:0;
    transition: opacity 0.5s;
}

.purple-background-fadein {
    opacity:1 !important;
    transition: opacity 0.5s;
}

#backgrounds {
    height:100vh;
    width:100vw;
    position:fixed;
}

#purple-bg {
    background: radial-gradient(circle, #151518, #030304);
    width:100%;
    height:100%;
    z-index: 2;
    position:absolute;
}

#purple-bg-1 {
    opacity: 0.4;
    background: #5B32B1;
    mix-blend-mode: overlay;
    width:100%;
    height:100%;
    z-index: 4;
    position:absolute;
}

#purple-bg-2 {
    opacity: 0.3;
    background: radial-gradient(50% 50% at 50% 50%, #383446 0%, #06001F 100%);    
    width:100%;
    height:100%;
    z-index: 3;
    position:absolute;
}

#top-nav {
    position:fixed;
    top:0;
    width:100%;
    z-index:100;
    display:flex;
    justify-content: space-between;
    align-items: flex-start;
    padding:0 0 0 3.42em;
    box-sizing: border-box;
    pointer-events: none;
    opacity:0;
}

#top-nav-hamburger {
    display:none;
}

#bottom-nav {
    position:fixed;
    bottom:0;
    right:0;
    width:100%;
    padding:0 3.42em 2.28em 0;
    display:flex;
    justify-content: flex-end;
    align-items: center;
    box-sizing: border-box;
    gap:2.28em;
    z-index:100;
    pointer-events: none;
    opacity:0;
}

#bottom-nav svg {
    filter:invert(100%);
    opacity:0.6;
    transition:opacity 0.15s;
    width:1.88em;
}

#bottom-nav .icon2 svg {
    width:1.3em;
}

#bottom-nav .icon3 svg {
    width:1.3em;
}

#bottom-nav svg:hover {
    opacity:1;
    transition:opacity 0.15s;
}

#website-logo {
    width:2.14em;
    margin:2.57em 0 0 0;
}

#nav-right-panel {
    display: flex;
    width:7.71em;
    box-sizing: border-box;
    flex-direction: column;
    padding: 1.71em 0 0 0;
    gap: 2vh;
    list-style-type:none;
}

#nav-right-panel img {
    display:none;
}

.nav-module {
    color:#FFFFFF5C;
    font-family: Montserrat;
    font-size: 1.14em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    transition:0.15s;
    width:100%;
    display:block;
    box-sizing: border-box;
}

.nav-module:hover:not(.active) {
    color:#B6ABFF;
    border-right: 6px solid rgba(255, 255, 255, 0.10);
    transition:color 0.15s;
}

.active {
    color:#B6ABFF;
    border-right: 6px solid #B6ABFF;
    transition:color 0.15s;
}

/* central div */

#central-div-1 {
    z-index:9;
    display: flex;
    box-sizing: border-box;
    position:relative;
    width:100vw;
    justify-content: space-between;
    padding:25vh 10vw 0 10vw;
    color:white;
    font-family: 'Montserrat';
    line-height: normal;
    box-sizing: border-box;
}

.central-left-div {
    font-weight:700;
}

#central-div-1 .central-left-div {
    opacity:0;
    transform:translateY(2em);
}

/* #name-div {
    font-size: 5.71em;
    font-style: normal;
} */

#central-div-1 .central-left-div div:nth-of-type(2){
    font-size: 1.42em;
    margin:4vh 0 8vh 0;
    opacity:0.9;
}

#name-div img {
    width:28em;
    margin:0 0 0 -2em;
}

/* #name-div span:nth-of-type(1) {
    margin: 0 0 0 -0.8em;
    color: #5346A5;
    opacity: 0.46;
    display:block;
}

#name-div span:nth-of-type(2) {
    color: #5346A5;
    opacity: 0.7;
}

#name-div span:nth-of-type(3) {
    color: #7D6DE2;
    opacity: 0.7;
} */

#central-div-1 .central-left-div div:nth-of-type(2) span:nth-of-type(1){
    color:rgba(255, 255, 255, 0.36);
}

.central-left-div div:nth-of-type(2) span:nth-of-type(2){
    color:rgba(255, 255, 255, 0.87);
}

.central-right-div {
    margin: 2.8em 0 0 0;
    display: flex;
    width: 10em;
    flex-direction: column;
    align-items: flex-start;
    /* gap: 3.5vh; */
    font-size: 2em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    opacity:0.9;
    color:rgba(255, 255, 255, 0.36)
}

#central-div-1 .central-right-div {
    opacity:0;
    transform:translateY(2em);
    gap: 3.5vh;
}

#central-div-1 .central-right-div div:nth-of-type(2) {
    display: flex;
    align-items: center;
    gap: 0.57em;
    color: rgba(255, 255, 255, 0.36);
    font-family: 'Roboto';
    font-size: 0.6em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#central-div-1 .central-right-div div:nth-of-type(2) img {
    width:0.6em;
}

#central-div-1 .central-right-div div:nth-of-type(1) span {
    background: linear-gradient(97deg, #7c68ff 11.65%, #a698ff 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: Montserrat;
    font-size: 1em;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}

#bg-banner-1 {
    background: radial-gradient(50% 50% at 50% 50%, rgba(255, 255, 255, 0.36) 0%, rgba(255, 255, 255, 0.11) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;    font-family: Montserrat;
    font-size: 14vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    top:50vh;
    z-index:5;
    opacity:0.12;
    width:90%;
    display: flex;
    justify-content: space-between;
    margin:0 4vw 0 -3vw;
    box-sizing: border-box;
    pointer-events: none;
    opacity:0;
}

#bg-banner-2 {
    color: rgba(255, 255, 255, 0.36);
    font-size: 14vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    top:45%;
    z-index:5;
    opacity:0.12;
    width:100%;
    padding:0 0 0 2vw;
    box-sizing: border-box;
    pointer-events: none;
}

#bg-banner-3 {
    color: rgba(255, 255, 255, 0.36);
    font-size: 14vw;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    position: absolute;
    top:15%;
    z-index:5;
    opacity:0.12;
    width:100%;
    padding:0 0 0 2vw;
    box-sizing: border-box;
    pointer-events: none;
}

/* second central div */

#central-div-2 {
    z-index:9;
    display: flex;
    box-sizing: border-box;
    position:relative;
    width:100vw;
    justify-content: space-between;
    padding:40vh 10vw 10vh 10vw;
    color:white;
    font-family: 'Montserrat';
    line-height: normal;
    font-style: normal;
    box-sizing: border-box;
}

/* .central-left-div .title {
    opacity:1;
} */

.central-left-div .title img {
    width: 28em;
}

/* .central-left-div .title span:nth-of-type(2) {
    color: #7D6DE2;
} */

#central-div-2 .central-left-div {
    display:flex;
    flex-direction: column;
    align-items: flex-start;
    gap:5vh;
}

/* work ex selector */

.work-ex-selector {
    display:flex;
    flex-direction:column;
    gap:0.57em;
    opacity:0.8;
    cursor: pointer;
    transition:0.15s;
    width:100%;
}

.work-ex-selector div:nth-of-type(1) {
    color: rgba(255, 255, 255, 0.36);
    font-size: 1.42em;
    font-weight: 700;
    pointer-events: none;
}

.work-ex-selector div:nth-of-type(2) {
    color: rgba(255, 255, 255, 0.36);
    font-size: 1.14em;
    font-weight: 400;
    display:flex;
    flex-direction: row;
    gap:0.57em;
    align-items: center;
    pointer-events: none;
}

.work-ex-selector div:nth-of-type(2) span:nth-of-type(1){
    color: rgba(255, 255, 255, 0.36);

}

.work-ex-selector div:nth-of-type(2) span:nth-of-type(2){
    color: rgba(255, 255, 255, 0.36);
}

/* work ex active */

.work-ex-active {
    opacity:1;
    box-shadow: -23.5em 0 0 rgba(5, 3, 16, 0.7),  -22.5em 0 0 rgba(182, 171, 255, 0.3);
}

.work-ex-active div:nth-of-type(1) {
    color: #B6ABFF;
}

.work-ex-active div:nth-of-type(2) {
    color: rgba(255, 255, 255, 0.87);
}

.work-ex-active div:nth-of-type(2) span:nth-of-type(1){
    color: rgba(255, 255, 255, 0.87);
}

.work-ex-active div:nth-of-type(2) span:nth-of-type(2){
    color: rgba(255, 255, 255, 0.55);
}

/* work ex hover */

.work-ex-selector:hover:not(.work-ex-active) {
    opacity:1;
}

.work-ex-selector:hover:not(.work-ex-active) div:nth-of-type(1) {
    color: rgba(255, 255, 255, 0.65);
}

.work-ex-active:hover:not(.work-ex-active) div:nth-of-type(2) {
    color: rgba(255, 255, 255, 0.55);
}

.work-ex-selector:hover:not(.work-ex-active) div:nth-of-type(2) span:nth-of-type(1) {
    color: rgba(255, 255, 255, 0.55);
}

.work-ex-selector:hover:not(.work-ex-active) div:nth-of-type(2) span:nth-of-type(2){
    color: rgba(255, 255, 255, 0.55);
}

/* right div */

#central-div-2 .central-right-div {
    font-family: Montserrat;
    margin:0.8em 0 0 0;
    width:10em;
    opacity:1 !important;
}

#central-div-2 .div-right-nos {
    display:flex;
    width:10em;
    justify-content: space-between;
    align-items: flex-end;
    margin:0 0 4vh 0;
}

#central-div-2 .div-right-nos div{
    display:flex;
    flex-direction: column;
}

#central-div-2 .div-right-nos div span:nth-of-type(1){
    background: linear-gradient(161deg, #7B67FF 1.93%, rgba(125, 109, 226, 0.45) 86.71%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 1.5em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#central-div-2 .div-right-nos div span:nth-of-type(2){
    color: #B6ABFF;
    font-size: 0.71em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#central-div-2 .div-right-desc {
    display:flex;
    flex-direction: column;
    gap:3.5vh;
    align-items: flex-start;
    color:rgba(255, 255, 255, 0.36);
    font-size: 0.57em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#central-div-2 .div-right-desc .desc-white {
    color:rgba(255, 255, 255, 0.87);
}

#central-div-2 .div-right-desc a {
    display:flex;
    gap: 0.56em;
    align-items: center;
    color: #B6ABFF;
    font-family: Roboto;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition:gap 0.15s;
}

#central-div-2 .div-right-desc a img {
    width:1em;
}

#central-div-2 .div-right-desc a:hover {
    color: #B6ABFF;
    gap: 1em;
}

#work-ex-tata, #work-ex-idc, #work-ex-spa {
    display:none;
}

/* #work-ex-tata, #work-ex-idc, #work-ex-spa {
    opacity:0 !important;
    position:absolute;
    right:0;
    transition:2s;
} */

/* central div 3 */

#central-div-3 {
    z-index:9;
    box-sizing: border-box;
    position:relative;
    width:36.3em;
    margin:0 10vw 4vh 18vw;
    color:white;
    font-family: 'Montserrat';
    line-height: normal;
    overflow: visible;
    box-sizing: border-box;
}

#central-div-3 .central-left-div {
    display:flex;
    flex-direction: column;
    gap:5vh;
}

#work-exs {
    display:flex;
    flex-direction: column;
    gap:5vh;
}

#skills-div {
    display:flex;
    flex-direction: column;
    gap: 3.8vh;
}

#skills-div .skill-div {
    display:flex;
    flex-direction: column;
    gap: 1.8vh;
}

#skills-div .skill-div .skill-div-heading{
    color: #7D6DE2;
    font-family: Montserrat;
    font-size: 1.42em;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#skills-div .skill-div .skill-div-content{
    color:rgba(255, 255, 255, 0.87);
    font-family: Montserrat;
    font-size: 1.14em;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display:flex;
    gap:0.57em 0.85em;
    flex-wrap:wrap;
}

#skills-div .skill-div .skill-div-icons{
    display:flex;
    align-items: center;
    gap:1.14em;
}

#skills-div .skill-div .skill-div-icons img{
    width:1.57em;
    opacity: 0.6;
    filter: none;
    -webkit-filter: grayscale(100%);
    -moz-filter:    grayscale(100%);
    -ms-filter:     grayscale(100%);
    -o-filter:      grayscale(100%);
    transition: 0.15s;
}

#skills-div .skill-div .skill-div-icons img:hover{
    width:1.57em;
    opacity: 1;
    filter: none;
    -webkit-filter: grayscale(0%);
    -moz-filter:    grayscale(0%);
    -ms-filter:     grayscale(0%);
    -o-filter:      grayscale(0%);
    transition: 0.15s;
}

/* project container begins */

#project-container {
    width:100vw;
    padding:7em 0 2.28em 0;
    position:relative;
    z-index: 20;
    display:flex;
    flex-direction: column;
    gap:8vh;
    color:white;
}

.projects-width {
    display:flex;
    width:62em;
    flex-direction: column;
    gap:7vh;
    margin:0 auto;
    padding:0 0 8vh 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.18);
}

.projects-header {
    color: #5346A5;
    font-family: Montserrat;
    font-size: 2.5em;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    width:100%;
    box-sizing: border-box;
}

#work-projects-svg {
    width:10em;
    /* margin:0 auto; */
}

.project {
    display:flex;
    flex-direction: row;
    align-items: center;
    gap:5.14em;
    width:100%;
    margin:0 auto;
    position:relative;
    border-radius:1.6em;
    outline:1em solid #10072900;
    transition:background-color 0.2s, outline 0.2s, transform 0.5s, opacity 0.5s;
    cursor: pointer;
}

.project:hover {
    background-color:#140b2da9;
    outline:1em solid #140b2d8a;
    transition:background-color 0.2s, outline 0.2s, transform 0.5s, opacity 0.5s;
}

.project:nth-of-type(2n+1) {
    flex-direction: row-reverse;
}

.project-image {
    width:35.71em;
    height:auto;
    border-radius:1.6em;
}

.project-info {
    display:flex;
    flex-direction: column;
    gap: 1.71em;
}

.project-links {
    display:flex;
    gap:1em;
}

.project:nth-of-type(2n+1) .project-info {
    align-items: flex-end;
}

.project-title {
    color:rgba(255, 255, 255, 0.87);
    font-family: Montserrat;
    font-size: 2em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.project:nth-of-type(2n+1) .project-title {
    text-align: right;
}

.project-desc {
    color: rgba(255, 255, 255, 0.55);
    font-family: Roboto;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.project:nth-of-type(2n+1) .project-desc {
    text-align: right;
}

#project-container a {
    display:flex;
    gap: 0.56em;
    align-items: center;
    color: #B6ABFF;
    font-family: Roboto;
    font-size: 0.9em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    transition:gap 0.15s;
}

#project-container a img {
    width:1em;
}

.project-white-text {
    color: rgba(255, 255, 255, 0.87);
}

#project-container .project-corners {
    display:flex;
    flex-direction: column;
    height:100%;
    justify-content: space-between;
    position:absolute;
    right:0;
    pointer-events: none;
    transition:all 0.2s cubic-bezier(0.69, -0.49, 0.42, 1.4) 0s;
}

#project-container .project:nth-of-type(2n+1) .project-corners {
    left:0;
}

#project-container .project:nth-of-type(2n+1) .project-corners img {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}

#project-container .project-corners img {
    width:1em;
}

.top-corner, .bottom-corner{
    transition:all 0.2s cubic-bezier(0.69, -0.49, 0.42, 1.4) 0s;
}

.project:nth-of-type(2n):hover .project-corners {
    margin-right:-5px;
}

.project:nth-of-type(2n+1):hover .project-corners {
    margin-left:-5px;
}

.project:nth-of-type(2n):hover .project-corners .top-corner{
    margin-top:-5px;
    transition:all 0.2s cubic-bezier(0.69, -0.49, 0.42, 1.4) 0s;
}

.project:nth-of-type(2n):hover .project-corners .bottom-corner{
    margin-bottom:-5px;
    transition:all 0.2s cubic-bezier(0.69, -0.49, 0.42, 1.4) 0s;
}

.project:nth-of-type(2n+1):hover .project-corners .top-corner{
    margin-top:-5px;
    transition:all 0.2s cubic-bezier(0.69, -0.49, 0.42, 1.4) 0s;
}

.project:nth-of-type(2n+1):hover .project-corners .bottom-corner{
    margin-bottom:-5px;
    transition:all 0.2s cubic-bezier(0.69, -0.49, 0.42, 1.4) 0s;
}

/* also check div */

#also-check {
    display:flex;
    justify-content: space-between;
    width:100%;
    margin:0 auto;
    position:relative;
}

.also-check-child {
    display: flex;
    flex-direction: column;
    gap:1.71em;
    width: 30em;
    padding:0 0 1.6em 0;
    outline:1em solid #10072900;
    transition:background-color 0.2s, outline 0.2s, transform 0.5s, opacity 0.5s;
    cursor: pointer;
    border-radius:1.6em;
}

#also-check-out-anchor #also-check-out-waititshere-a {
    display: flex;
    flex-direction: column;
    gap:1.71em;
    width: 30em;
    padding:0 0 1.6em 0;
    outline:1em solid #10072900;
    transition:background-color 0.2s, outline 0.2s, transform 0.5s, opacity 0.5s;
    cursor: pointer;
    border-radius:1.6em;
    font-size:1em;
}

#also-check-out-waititshere-a #waititshere-banner {
    width:100%;
}

#also-check-out-waititshere-a #waititshere-external-icon {
    width:0.8em;
}

.also-check-child:hover {
    background-color:#140b2d8a;
    outline:1em solid #140b2d8a;
    transition:background-color 0.2s, outline 0.2s, transform 0.5s, opacity 0.5s;
}

.also-check-child .also-check-card {
    display: flex;
    flex-direction: column;
    gap: 0.42em;
    width:100%;
    box-sizing: border-box;
}

.also-check-child .also-check-title {
    color: rgba(255, 255, 255, 0.87);
    font-family: Montserrat;
    font-size: 2em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.also-check-child .also-check-title img {
    width:0.6em;
}

.also-check-child .also-check-content {
    color:rgba(255, 255, 255, 0.55);
    font-family: Roboto;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.also-check-child img {
    border-radius: 1.6em;
}

/* footer */

#footer {
    text-align: center;
    color: rgba(255, 255, 255, 0.55);
    font-family: Montserrat;
    font-size: 1.14em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    height:1.7em;
    box-sizing: border-box;
}

/* form */

.projects-width:nth-of-type(3) {
    width: 30em;
    border-bottom:none;
    padding:0 0 4vh 0;
    box-sizing: border-box;
}

#contact-form {
    display:flex;
    flex-direction: column;
    gap: 1.42em;
}

#contact-form .form-row {
    display:flex;
    flex-direction: column;
    gap: 0.57em;
}

#contact-form .form-label {
    color: rgba(255, 255, 255, 0.55);
    font-family: Roboto;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#contact-form .form-field {
    padding: 0.57em 0.6em;
    border: 1px solid rgba(255, 255, 255, 0.36);
    background:none;
    color:rgba(255, 255, 255, 0.87);
    font-size:1em;
    transition: border 0.075s;
    box-sizing: border-box;
    font-family: 'Roboto';
    border-radius: 0;
    -webkit-border-radius: 0;
}

#contact-form .form-field:hover {
    border: 1px solid rgba(255, 255, 255, 0.87);
}

#contact-form .form-field:focus {
    outline: none !important;
    border: 1px solid #B6ABFF;
}

#contact-form .form-text-area {
    /* height: 2.28em; */
    /* padding: 0.57em 0.85em; */
    border: 1px solid rgba(255, 255, 255, 0.36);
    background:none;
    resize:vertical;
    min-height:12em;
    color:rgba(255, 255, 255, 0.87);
    padding: 0.57em 0.6em;
    font-size:1em;
    transition: border 0.075s;
    font-family: 'Roboto';
    border-radius: 0;
    -webkit-border-radius: 0;
}

#contact-form .form-text-area:hover {
    border: 1px solid rgba(255, 255, 255, 0.87);
}

#contact-form .form-text-area:focus {
    outline: none !important;
    border: 1px solid #B6ABFF;
}

#contact-form .form-buttons {
    display:flex;
    width:100%;
    justify-content: space-between;
    transition:0.15s;
}

#contact-form .form-buttons .button {
    transition:0.15s;
}

#contact-form .form-buttons .button:nth-of-type(2) {
    background: #5346A5;
    color: rgba(255, 255, 255, 0.87);
}

/* .form-buttons .button:nth-of-type(2):hover {
    display:flex;
    width:100%;
    justify-content: space-between;
} */

#contact-form .form-buttons .button:hover {
    border: 1px solid #5346A5;
    background-color: #5346A5;
    color:rgba(255, 255, 255, 0.87);
    transition: 0.15s;
}

#about-nav-anchor {
    position:absolute;
    width:10vw;
    height:100px;
    opacity:0;
    top:28vh;
}

.light-purple {
    color: #7D6DE2;
}

#back-to-top {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.56em;
    color: #B6ABFF;
    font-family: Roboto;
    font-size: 1em;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#back-to-top img {
    transition:0.2s ease-in-out;
}

#back-to-top:hover img {
    transform:translateY(-0.2em);
    transition:0.1s ease-in-out;
}

#grainy-svg {
    width:100vw;
    height:100vh;
    position:fixed;
    top:0;
    z-index: 10;
    mix-blend-mode: normal;
    opacity:0.1; /* increase to 0.2 for the grainy effect */
    pointer-events: none;
}


.nav-right-panel-hidden {
    opacity:1;
    transform: translateX(0);
}



/* project dialogs _________________________________________________________________________________________________________ */

/* .project-dialog-container {
    position:absolute;
    width:100vw;
    top:0;
    left:0;
    display:flex;
    justify-content: center;
} */

dialog::-webkit-scrollbar {
    width: 0;
}

/* dialog::-webkit-scrollbar-track {
    background: none;
}
  
dialog::-webkit-scrollbar-thumb {
    border-radius: 4px;
    border:0.1em solid #1A1631;
    background: rgba(67, 61, 105, 0.5);
} */

.project-dialog-close-icon {
    background:none;
    color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

dialog::backdrop {
    background-color: rgb(0, 0, 0);
    opacity: 0.75;
    -webkit-animation: none;
}

dialog {
    width:60vw;
    max-height: 100vh;
    background: none;
    color: rgba(255, 255, 255, 0.8);
    padding:0;
    margin-top : 3vh;
    margin-bottom:10vh;
    border:none;
    overflow-x: hidden;
    border-top-left-radius: 1.2em;
    border-top-right-radius: 1.2em;
}

dialog:focus {
    outline : none;
}

dialog[open] {
    -webkit-animation: show 0.3s ease-out;
}
@-webkit-keyframes show{
    from {
        transform: translateY(2.5%);
        opacity:0;
    }
    to {
        transform: translateY(0%);
        opacity:1;
    }
}

dialog[open]::backdrop {
    -webkit-animation: show-backdrop 0.2s ease-out;
}
dialog.hide::backdrop {
    -webkit-animation: hide-backdrop 0.2s ease-out;
}
@-webkit-keyframes show-backdrop {
    from {
        opacity: 0;
    }
    to {
        opacity: 0.75;
    }
}
@-webkit-keyframes hide-backdrop{
    to {
        opacity: 0;
    }
}

dialog.hide {
    -webkit-animation: hide 0.3s ease-out;
}
@-webkit-keyframes hide{
    to {
        transform: translateY(2.5%);
        opacity:0;
    }
}


.project-dialog-close-icon {
    opacity:0.75;
    transition:0.1s;
}

.project-dialog-close-icon:hover {
    opacity:1;
    transition:0.1s;
}

body.dialog-open {
    overflow: hidden;
    margin-right: 15px !important;
}

.dialog-container-div {
    display:flex;
    flex-direction: column;
    gap:0;
}

.dialog-content {
    display:flex;
    flex-direction: column;
    gap:2.28em;
    /* overflow-y: auto; */
    flex:1 1 auto;
    /* max-height: calc(100vh - 4vh - 6.28em - 10vh); */
    padding:2.28em;
    margin:0 0 8vh 0;
    background-color: #0F1122;
    z-index:200;
    border-bottom-left-radius: 1.2em;
    border-bottom-right-radius: 1.2em;
}

.project-dialog-top {
    display:flex;
    width:100%;
    justify-content: space-between;
    align-items: flex-start;
    overflow: hidden;
    flex:0 0 auto;
    padding:2.28em 2.28em 0 2.28em;
    box-sizing: border-box;
    position:sticky;
    top:0;
    background-color: #0F1122;
    z-index:250;
    gap:4em;
}

.project-dialog-header {
    display:flex;
    flex-direction:column;
    gap:0.57em;
}

.project-dialog-bannerimage {
    border-radius: 16px;
}

.project-dialog-bannerimage img {
    border-radius: 16px;
    width:100%;
    height:auto;
}

.project-dialog-title {
    font-family:'Montserrat';
    font-size:1.8em;
    transition:0.2s;
}

.project-dialog-subtitle {
    font-family: 'Roboto';
    font-size:1.14em;
    display:flex;
    gap:0.85em;
    align-items: flex-end;
    transition:0.2s;
}

.project-dialog-subtitle a {
    display:flex;
    align-items: flex-end;
    gap: 0.28em;
    color: #B6ABFF;
}

.project-dialog-subtitle a img {
    width:1em;
    height:auto;
}

.project-dialog-subtitle img {
    height:1em;
    width:auto;
}

.project-dialog-subtitle span {
    color:rgba(255, 255, 255, 0.36);
}

.project-dialog-section {
    display:flex;
    font-family: 'Roboto';
    flex-direction: column;
    gap:1.14em;
}

.project-container-section-divider {
    width:100%;
    height:1px;
    background-color: rgba(255, 255, 255, 0.12);
    min-height:1px;
}

.project-dialog-section-content {
    padding:0 0 0 28px;
}

.project-dialog-section-header {
    font-family: 'Montserrat';
    font-size:1.42em;
    font-weight: 700;
    padding:0 0 0 16px;
    border-left:12px solid #7D6DE2;
}

.project-dialog-section-subheader {
    font-family: 'Montserrat';
    font-size:1.14em;
    font-weight: 700;
    padding:0 0 0 16px;
    border-left:12px solid #7D6DE2;
    margin-top:0.5em;
}

.project-dialog-section-tertiary-header {
    font-family: 'Montserrat';
    font-size:1.14em;
    font-weight: 700;
    padding:0 0 0 28px;
    margin-top:0.5em;
}

.project-dialog-section-header-support {
    padding:0 0 0 28px;
    font-style: italic;
    color: rgba(255, 255, 255, 0.36);
    margin-top:-0.7em;
}

.project-dialog-content-cards {
    font-size:0.9em;
    display:flex;
    gap:1em;
    margin:0 0 0.75em 0;
}

.card {
    display: flex;
    flex-direction: column;
    gap:1.5em;
    border-radius: 1em;
    background-color: #171A33;
    padding:1.25em;
    width:100%;
}

#spacetime-audience img {
    width:3.5em;
    height:auto;
}

#spacetime-audience .card {
    width:8.5em;
    text-align: center;
    align-items: center;
    justify-content: center;
}

#spacetime-audience-2 span {
    color:rgba(255, 255, 255, 0.55);
    font-style: italic;
}

#spacetime-audience-2 .card{
    gap:0.8em;
}

.project-dialog-bullet-point {
    display:flex;
    align-items: flex-start;
    gap:0.6em;
}

.project-dialog-bullet-point img{
    height:1em;
}

.bullet-point-container {
    display:flex;
    flex-direction: column;
    gap:0.8em;
}

#spacetime-litreview-cards .card {
    flex-direction: row;
    gap:2em;
}

#spacetime-litreview-cards .card img {
    border-radius: 0.5em;
    width:auto;
    height:11em;
}

.project-multiline-card-container {
    display: flex;
    flex-direction: column;
    gap:0.25em;
}

.spacetime-litreview-content {
    display: flex;
    flex-direction: column;
    gap:1em;
}

.spacetime-litreview-content div:first-child{
    font-weight: 600;
}

.spacetime-litreview-content div:nth-of-type(2){
    color:rgba(255, 255, 255, 0.55);
}

.project-dialog-section-content a {
    font-family: 'Roboto';
    font-size:1em;
    color:#B6ABFF;
    display:flex;
    gap:0.25em;
    align-items: center;
}

.project-dialog-section-content a img{
    height:1.2em;
    width:auto;
}

#embedding-image {
    width:14em;
    height:auto;
    margin:1em auto;
}

.big-small-numbers {
    display:flex;
    gap:4em;
}

.big-small-numbers div {
    display:flex;
    flex-direction: column;
}

.big-small-numbers div span:first-child {
    font-size:2em;
    font-weight: 700;
    color:#B6ABFF;
}

/* .project-dialog-footer {
    display:flex;
    justify-content: space-between;
    padding:0 0 2.28em 0;
}

.project-dialog-footer .button:nth-of-type(2) {
    background: #5346A5;
    color: rgba(255, 255, 255, 0.87);
}

.project-dialog-footer .button img {
    width:1.2em;
    height:auto;
} */

.project-dialog-prev-next-buttons {
    display:flex;
    position:fixed;
    justify-content: space-between;
    width:91vw;
    top:60%;
    left:50%;
    transform:translate(-50%, -50%);
    font-family: 'Montserrat';
    font-size: 1.2em;
    color: #7D6DE2;
    font-weight:600;
    text-align:center;
    z-index:150;
}

.project-dialog-prev-next-buttons img{
    width:2.5em;
    height:auto;
}

.previous-project, .next-project {
    display:flex;
    flex-direction: column;
    gap:0.75em;
    width:5em;
    align-items: center;
    opacity:0.5;
    transition:0.2s;
    border-radius:1em;
    padding:1.2em;
    cursor: pointer;
}

.previous-project:hover {
    opacity:0.8;
    transition:0.2s;
    background-color: #0f1122a9;
}

.next-project:hover {
    opacity:0.8;
    transition:0.2s;
    background-color: #0f1122a9;
}

#spacetime-dialog .previous-project {
    opacity:0;
    pointer-events: none;
}

#genocorp-next-project-button {
    opacity:0;
    pointer-events: none;
}

#omni-intro-brands {
    width:50%;
    height:auto;
}

.omni-research-cards-left, .omni-research-cards-right {
    display:flex;
    flex-direction: column;
    gap: 0.8em;
}

.omni-research-cards-left {
    align-items: center;
    font-weight:600;
    width:50%;
    text-align: center;
}

.omni-research-cards-right {
    align-items: left;
    width:100%;
}


.omni-research-cards-left img, .omni-research-cards-right img {
    width:4em;
    height:auto;
}

#omni-research-cards .card {
    flex-direction:row;
    gap:1.8em;
}

#omni-methodologies-cards{
    color: rgba(255, 255, 255, 0.55);
}

#omni-methodologies-cards .card{
    gap : 0.8em;
}

#omni-methodologies-cards span{
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
}

#omni-competitor-apps {
    width:25%;
    height:auto;
}

#interview-screenshot-image {
    width:50%;
    border-radius:0.85em;
}

.project-bullets-grey-text {
    color:rgba(255, 255, 255, 0.55);
}

#omni-personas-cards .card {
    gap:1.6em;
}

.omni-persona-header {
    display:flex;
    gap:0.85em;
    align-items: flex-start;
}

.omni-persona-image {
    border-radius:0.6em;
    height:7em;
    width:auto;
}

.omni-persona-header-name {
    font-weight:700;
    font-size:1.14em;
}

.omni-persona-grey-text {
    font-weight:400;
    color:rgba(255, 255, 255, 0.55);
}

.omni-persona-header-right {
    display:flex;
    flex-direction: column;
    gap:0.4em;
}

.omni-persona-header-quality {
    font-style: italic;
    margin-top:-0.3em;
}

.omni-persona-header-qualities {
    color:rgba(255, 255, 255, 0.55);
    display: flex;
    align-items: center;
    flex-wrap:wrap;
    column-gap:0.4em;
}

.omni-persona-body {
    display:flex;
    flex-direction: column;
    height:100%;
    justify-content: space-between;
}

.omni-persona-body-content {
    display:flex;
    flex-direction: column;
    gap:1em;
}

.omni-persona-body-section ul {
    padding:0 0 0 1.25em;
    color:rgba(255, 255, 255, 0.55);
}

.omni-persona-body-brands {
    display:flex;
    gap:0.4em;
    color:rgba(255, 255, 255, 0.36);
    margin:1em 0 0 0;
}

.omni-persona-section-header {
    font-weight:700;
}

.omni-persona-motivations-container {
    display:flex;
    flex-direction: column;
    gap:0.4em;
}

.omni-persona-charts-container {
    display:flex;
    flex-direction: column;
    gap:0.5em;
    color:rgba(255, 255, 255, 0.55);
}

.omni-persona-motivations-chart {
    display:flex;
    flex-direction: column;
    gap:0.15em;
}

.omni-persona-motivations-chart img {
    width:100%;
    height:auto;
}

.omni-scenarios-image-container {
    display:flex;
    gap:1em;
    flex-wrap: wrap;
}

.omni-scenarios-image-container img {
    border-radius:1em;
    width:calc(50% - 0.5em);
}

#omni-personalisation-card {
    border: 1px solid #5346A5;
}

#omni-ia-cards .card {
    display:flex;
    flex-direction: column;
    align-items: center;
    gap:0.85em;
    color:rgba(255, 255, 255, 0.55);
}

#omni-ia-cards img {
    height:4em;
    width:auto;
}

.omni-ia-header {
    font-weight: 500;
    color:rgba(255, 255, 255, 0.8);
}

#omni-wireframes-image {
    border-radius:1em;
    width:100%;
    height:auto;
}

#iks-outcomes-cards {
    width:calc(50% - 1em);
    gap:1em;
}

#iks-outcomes-cards .card {
    gap:0.8em;
}

#iks-outcomes-cards span {
    font-style: italic;
    color:rgba(255, 255, 255, 0.55);
}

#iks-research-areas-cards img {
    height:4em;
    width:4em;
}

#iks-research-areas-cards .card {
    gap:0.85em;
    text-align: center;
    align-items: center;
}

.coach-scenario-arrow {
    height:1.2em;
    width:1.2em;
    margin: auto 0;
}

#coach-scenario-arrow-big {
    width:1.8em;
    height:1.8em;
}

#curriculum-img {
    width:100%;
    height:auto;
    padding:1.2em 0 0 0;
}

#iks-img {
    width:80%;
    height:auto;
}

#iks-litreview-cards .card {
    flex-direction: row;
}

#iks-focussed-review-img {
    width:48%;
}

.iks-litreview-card-header {
    font-weight: 700;
    color:rgba(255, 255, 255, 0.8);
}

#iks-focussed-litreview-cards .card {
    color: rgba(255, 255, 255, 0.55);
    gap:0.8em;
}

.iks-litreview-card-subheader {
    font-style: italic;
    margin-top:-0.5em;
}

.iks-litreview-card-content {
    display:flex;
    flex-direction: column;
    gap:0.8em;
}

#project-basic-card .card{
    gap:1em;
    align-items: center;
    text-align:center;
}

#project-basic-card .card img{
    width:4em;
    height:auto;
}

.project-basic-card-header {
    font-weight:700;
}

.project-basic-card-body {
    color: rgba(255, 255, 255, 0.55);;
}

#lesson-plan-structure-img {
    width:100%;
}

#lesson-plan-container {
    display:flex;
    gap:1.5em;
    padding-bottom:1em;
    align-items: flex-start;
}

#lesson-plan-container img{
    padding-top:0.5em;
}

.lesson-plan-card {
    display:flex;
    flex-direction: column;
    max-width:9em;
}

.lesson-plan-card span:first-of-type{
    font-weight:700;
    font-family:'Montserrat';
    font-size:1.3em;
}

.lesson-plan-card span:nth-of-type(2){
    color: rgba(255, 255, 255, 0.55);
}

.iks-quickview-white .iks-litreview-card-content {
    color:rgba(255, 255, 255, 0.8);
}

#iks-workbooks-cards {
    display:flex;
    gap:1em;
}

#iks-workbooks-cards .card {
    align-items: center;
    width:auto;
    height:100%;
    gap:2em;
}

#iks-workbooks-cards img{
    width:70%;
    height:auto;
}

.iks-workbook-cards-column {
    display:flex;
    flex-direction: column;
    gap:1em;
    width:100%;
}

#iks-workbook-cards-grey-text {
    color: rgba(255, 255, 255, 0.55);
    font-style: italic;
}

.image-screen {
    mix-blend-mode: screen;
}

#workbooks-grid-image {
    width:9em !important;
}

#talamana-container {
    display:flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 0.5em;
    border:3px solid #7D6DE2;
    width:100%;
    height:100%;
}

.talamana-column {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height:100%;
    align-items: center;
    text-align: center;
}

.talamana-item {
    display:flex;
    flex-direction: column;
}

.talamana-header {
    font-weight:700;
}

.talamana-subheader {
    color: rgba(255, 255, 255, 0.55);
}

#iks-exposure-img {
    width:90%;
}

#mannequin-website-img {
    border-radius:1em;
    width:80%;
}

#temple-fractal-img {
    width:100%;
}

#printed-temple {
    width:100%;
}

#evaluating-workbooks-img {
    width:100%;
}

#iks-evaluating-exercises-grey {
    color: rgba(255, 255, 255, 0.55);
}

#remote-brainstorming-img {
    width:100%;
}

.remote-youtube {
    border-radius:1em;
    position: relative;
    /* width: 100%; */
    height: 0;
    padding-bottom: 56.25%;
    margin-left:28px;
    overflow: hidden;
}

.remote-youtube iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

#remote-explorations-cards .card {
    gap:1em;
}

#remote-explorations-cards .card img {
    width:100%;
    border-radius: 0.6em;
}

#remote-state-of-art-img {
    width:100%;
}

#remove-placement-words {
    display:flex;
    gap:2em;
    color:#B6ABFF;
}

#hardware-backend-3d-img {
    width:100%;
    border-radius:1em;
}

#hardware-img-group {
    display:flex;
    flex-direction: column;
    gap:1em;
}

#hardware-img-group img {
    width:100%;
    border-radius:1em;
}

#hardware-images-row {
    display:flex;
    gap:1em;
    width: calc(50% - 0.5em);
}

.full-size-img {
    width:100%;
    border-radius:1em;
}

#songs-explorations-header-child img {
    width:4em;
}

#remote-explorations-card .card {
    align-items: flex-start;
}

#project-basic-card-header-explorations {
    display:flex;
    gap:1em;
    align-items: center;
    width:100%;
    justify-content: center;
    font-family:'Montserrat';
    font-size:2em;
    color:#5346a577;
}

.songs-explorations-header-child {
    display:flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap:0.8em;
    font-family:'Roboto';
    font-size:0.5em;
    color: rgba(255, 255, 255, 0.80);
}

.songs-two-in-three-cards {
    width:calc(66% - 1em);
}

.songs-litreview-cards img {
    width:100%;
    border-radius:0.6em;
}

.songs-litreview-cards .card {
    width:100%;
    gap:1em;
}

.songs-litreview-cards .project-basic-card-header {
    display:flex;
    flex-direction: column;
    gap:0.2em;
}

.songs-litreview-cards .project-basic-card-header span:nth-of-type(2){
    font-style: italic;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.55);
}

.songs-choosing-cards .card {
    gap:1em;
}

.songs-choosing-cards img {
    width:100%;
    border-radius:0.6em;
}

.songs-choosing-cards .project-basic-card-header img {
    width:auto;
    height:100%;
}

.songs-choosing-cards .project-basic-card-header {
    display:flex;
    gap:0.5em;
}

.songs-choosing-cards .project-basic-card-header div {
    display:flex;
    flex-direction: column;
    gap:0.2em;
}

.songs-choosing-cards .card {
    gap:0.8em;
}

.songs-choosing-cards .project-basic-card-header div span:nth-of-type(2){
    color: rgba(255, 255, 255, 0.55);
    font-weight: 400;
}

.songs-choosing-cards .project-basic-card-body {
    font-style: italic;
    color: rgba(255, 255, 255, 0.36);
}

#assigning-emotions-grid-img {
    width:100%;
    display:flex;
    justify-content: center;
    align-items: center;
}

#assigning-emotions-grid-img img {
    width:24em;
    mix-blend-mode: screen;
}

#converting-emotions-grid-img {
    mix-blend-mode: screen;
}

#generating-3d-models-cards img {
    width:4em;
}

#generating-3d-models-cards .card {
    align-items: center;
    gap:0.8em;
}

#generating-3d-models-cards .project-basic-card-header {
    width:100%;
}

#generating-3d-models-cards .project-basic-card-body {
    width:100%;
}

.songs-3d-cards {
    flex-direction: column;
}

.songs-3d-cards .card {
    flex-direction: row;
    gap:1.25em;
    justify-content: space-between;
}

.songs-3d-cards .card img {
    width:100%;
    border-radius:0.6em;
}

.songs-3d-cards {
    box-sizing: border-box;
}

.songs-3d-cards .card {
    box-sizing: border-box;
}

.songs-3d-cards .card .project-basic-card-header img {
    width:auto;
    height:100%;
}

.song-3d-card-column {
    display:flex;
    flex-direction: column;
    gap:0.8em;
    width:20em;
    box-sizing: border-box;
}

.songs-3d-cards .card .project-basic-card-header {
    display:flex;
    flex-direction: row;
    gap:0.5em;
    align-items: center;
}

/* .songs-3d-cards .card {
    width:auto;
} */

.songs-3d-cards .card .project-basic-card-header div {
    display:flex;
    flex-direction: column;
    gap:0.2em;
}

.songs-3d-cards .card .project-basic-card-header div span:nth-of-type(2) {
    font-weight:400;
    color: rgba(255, 255, 255, 0.55);
}

.songs-3d-cards .project-basic-card-body {
    color: rgba(255, 255, 255, 0.8);
}

.purple-tertiary-header {
    color:#7D6DE2;
}

#createnshare-persona {
    width:18em;
}

#createnshare-persona .omni-persona-image {
    height:6em;
}

#createnshare-dialog .createnshare-not-full-width {
    width:60%;
}

#createnshare-ia .full-size-img {
    border-radius: 0;
    padding:1em 0;
}

#createnshare-wireframes {
    mix-blend-mode: lighten;
}

.createnshare-prototypes .card {
    box-sizing: border-box;
    align-items: center;
}

.createnshare-prototypes .card img{
    width:55%;
}

#bonfire-user-group {
    width:100%;
    display:flex;
    justify-content: center;
}

#bonfire-user-group img {
    width:45%;
}

.createnshare-features img {
    width:70%;
    mix-blend-mode: screen;
}

.createnshare-features .card {
    flex-direction: row;
    box-sizing: border-box;
    justify-content: center;
}

#bonfire-smaller-feature-img img {
    width:62%;
}

#guardian-image-container {
    display:flex;
    flex-direction: column;
    gap:0;
    border-radius: 1em;
    overflow:hidden;
}

#guardian-image-container img {
    width:100%;
}

#procedural-knowledge img {
    width:25em;
    padding:2em 0;
}

#coach-man img {
    width:50%;
    mix-blend-mode: lighten;
}

.coach-mocks img {
    width:80%;
}

.coach-mocks .card {
    width:100%;
    box-sizing: border-box;
    align-items: center;
    justify-content: center;
    gap:0;
}

.invisible {
    opacity:0 !important;
}

.only-arrow {
    background: none;    
}

.only-arrow img {
    height:2em;
    width:2em;
    margin:0 auto;
}

.down-arrow-container {
    margin:-2.5em 0 -1.5em 0;
}

.coach-algorithms img {
    width:100%;
    mix-blend-mode: lighten;
}

.coach-algorithms .card {
    padding:2em 0;
}

.coach-comment-refinement img {
    width:50%;
    mix-blend-mode: lighten;
}

.coach-comment-refinement .card {
    align-items: center;
    padding:2em 0;
}

#genocorp-character-creation-image img {
    width:40%;
    border-radius: 1em;
}

#genocorp-graph img {
    mix-blend-mode: lighten;
    width:50%;
}

#genocorp-timeline img {
    width:100%;
}

.genocorp-mocks img {
    width:75%;
}

.genocorp-mocks img:nth-of-type(1) {
    margin:0 0 -4em 0;
}

.genocorp-mocks .card {
    align-items: center;
    box-sizing: border-box;
}

#architecture-image-container {
    display:flex;
    flex-direction: column;
    gap:0.6em;
}

#architecture-image-container img {
    width:100%;
    border-radius: 1em;
}

/* media queries ___________________________________________________________________________________________________________ */

@media (max-width:600px) {
    body {
        font-size:16px;
    }
    #artboard {
        position:fixed;
    }
    #backgrounds {
        position: fixed;
        top:0;
    }

    #loader {
        width:7em;
    }
    #loading-bar-container {
        height:0.2em;
        width:100%;
        background-color: #1A1631;
        border-radius: 0.2em;
    }
    #loading-text {
        font-size: 1.4em;
    }

    #top-nav {
        width:100%;
        padding:2.5em 2.5em;
        background: linear-gradient(180deg, #0A0217 0%, rgba(14, 3, 33, 0.00) 100%);
        align-items: center;
    }
    #top-nav-hamburger {
        display:block;
        width:28px;
        pointer-events: all;
    }
    #nav-right-panel {
        position:absolute;
        top:0;
        right:0;
        padding:2.6em 2.5em 0 0;
        align-items: flex-end;
        font-size: 1.1em;
        gap:3vh;
        height:100vh;
        width:15em;
        background: linear-gradient(270deg, #090714 0%, rgba(10, 7, 21, 0.87) 51%, rgba(10, 8, 21, 0.70) 75%, rgba(10, 8, 21, 0.00) 100%);
        transition:opacity 0.4s;

    }
    .nav-right-panel-hidden {
        opacity:0;
        transform:translateX(100em);
        transition:opacity 0.4s;
    }
    .nav-module:hover:not(.active) {
        border: none;
        color:#ffffff5c;
    }
    #nav-right-panel img {
        width:24px;
        display: block;
        pointer-events: all;
        z-index: 1000;
    }
    #nav-right-panel li:first-of-type {
        display:flex;
        flex-direction: column;
        align-items: flex-end;
        gap:2em;
    }
    .active {
        border:none !important;
    }
    .active:hover {
        border:none !important;
    }

    #website-logo {
        width:32px;
        margin:0;
    }
    .central-left-div {
        width:100%;
    }
    #central-div-1 .central-left-div {
        width:80%;
    }
    #central-div-1 {
        width:100%;
        height:80vh;
        padding:6em 2.5em 0;
        flex-direction: column;
        align-items: center;
    }
    #bg-banner-1 {
        flex-direction: column;
        align-items: center;
        width:100%;
        margin:0 2.5em;
        font-size:30vw;
        top:40vh;
        display:none;
    }
    #name-div img {
        width:100%;
        margin:0;
    }
    #central-div-1 .central-left-div div:nth-of-type(2) {
        font-size:1em;
        text-align:center;
        margin:1.2em 0 0 0;
    }
    #central-div-2 {
        width:100%;
        height:100vh;
        padding:2em 2.5em 2em;
        opacity:0;
    }
    #bg-banner-2 {
        /* flex-direction: column;
        align-items: center;
        width:50%;
        margin:0 auto;
        padding:0;
        font-size:26vw;
        top:40vh;
        text-align: center; */
        display:none;
    }
    .central-left-div .title img {
        width:100%;
        margin:0;
    }
    #central-div-2 .central-left-div {
        align-items: center;
        gap:3em;
    }
    #about-nav-anchor {
        top:0;
    }
    #work-exs {
        align-items: center;
    }
    .work-ex-selector {
        align-items: center;
        opacity:1;
    }
    .work-ex-selector div:first-of-type {
        font-size:1.2em;
        color: #b6abff;
    }
    .work-ex-selector div:nth-of-type(2) span:first-of-type {
        color: #ffffffde;
    }
    .work-ex-selector div:nth-of-type(2) img {
        color: #ffffffde;
    }
    .work-ex-selector div:nth-of-type(2) {
        font-size:1em;
    }
    .work-ex-selector div:nth-of-type(2) span:nth-of-type(2) {
        color: #ffffff8c;
    }
    .work-ex-active {
        box-shadow: none;
    }
    #central-div-3 {
        width:100%;
        height:100vh;
        padding:2em 2.5em 2em;
        margin:0;
    }
    #central-div-3 .central-left-div {
        align-items: center;
        gap:2em;
    }
    #central-div-3 .central-left-div title {
        width:100%;
    }
    #skills-div {
        width:100%;
        align-items: center;
    }
    #skills-div .skill-div {
        width:100%;
        align-items: center;
    }
    #skills-div .skill-div .skill-div-heading {
        font-size:1.2em;
    }
    #skills-div .skill-div .skill-div-content {
        align-items: center;
        justify-content: center;
        gap:0.5em 0.9em;
        font-size:1em;
    }
    #bg-banner-3 {
        display:none;
    }

    #project-container {
        width:100%;
        /* padding:2em 2.5em 2em; */
        margin:0;
        gap:3em;
    }
    #work-projects-svg {
        width:10em;
        margin:0 auto;
    }
    .project {
        flex-direction: column !important;
        gap:1.5em;
        margin:0;
    }
    .project-image {
        width:100vw;
        border-radius: 0;
        /* margin-left:-5em; */
    }
    .project-info {
        /* width:100%; */
        align-items: flex-start !important;
        gap:1em;
        padding:0 1.5em;
    }

    .also-check-child .also-check-card {
        gap:1em;
    }
    .projects-width {
        width:100%;
        gap:3em;
        border:none;
    }
    .project:nth-of-type(odd) .project-desc {
        text-align: left;
    }
    .project-title {
        font-size: 1.6em;
    }
    .project-desc {
        width:100%;
    }
    #also-check {
        flex-direction: column;
        gap:3em;
    }
    .projects-header {
        font-size: 1.6em;
        padding:0 2.5rem;
    }
    .also-check-child {
        width:100%;
        gap:1.5em;
    }
    .also-check-child img {
        width:100vw;
    }
    .also-check-child .card {
        padding:0 2.5em;
        gap:1.5em;
    }
    .also-check-child .also-check-title {
        font-size: 1.6em;
    }
    .also-check-child .also-check-content {
        width:100%;
    }
    .also-check-child img {
        border-radius: 0;
    }
    .projects-width:nth-of-type(3) {
        width:100%;
        padding:0 1.5em;
        gap:2.5em;
    }
    .projects-width:nth-of-type(3) .projects-header {
        padding:0
    }
    #contact-form {
        width: 100%;
    }
    .form-row, .form-row input {
        width:100%;
    }
    #back-to-top {
        width:100%;
    }
    #footer {
        width:100%;
        font-size: 1em;
        padding:0 1.5em;
    }
    #bottom-nav {
        position:relative;
        justify-content: center;
        align-items: center;
        padding: 2em 0 3em 0;
        margin: 0;
    }
    #bottom-nav .icon1 svg {
        width:2.4em;
    }
    #bottom-nav .icon2 svg {
        width:1.8em;
    }
    #bottom-nav .icon3 svg {
        width:1.8em;
    }
    #central-div-1 .central-right-div, #central-div-1 .central-left-div .button, #central-div-2 .central-right-div, .project-corners, #back-to-top {
        display:none !important;
    }
    .div-fade-out {
        opacity:1 !important;
        transition-property: transform, opacity;
        transition-duration: 0.4s;
        transition-timing-function: ease-out;
    }

    dialog {
        width:100vw;
        margin:0.5em auto 0;
        border:none;
        overflow-x: hidden;
        border-top-left-radius: 1.2em;
        border-top-right-radius: 1.2em;
    }

    dialog:modal {
        max-width:100vw;
        max-height: 100vh;
    }

    .project-dialog-title {
        font-size:1.4em;
    }

    .project-dialog-top {
        padding:20px 20px 0;
        gap:1.5em;
    }

    .project-dialog-subtitle {
        font-size:1em;
        gap:0.5em;
    }

    .dialog-content {
        gap:1.6em;
        padding:1.6em 0 7em 0;
        margin:0 0 0 0;
    }

    .project-dialog-section {
        gap:1em;
        padding:0 20px 0 0;
    }

    .project-dialog-section-content {
        padding:0 0 0 20px;
    }

    .project-dialog-bannerimage {
        border-radius: 0;
    }
    
    .project-dialog-bannerimage img {
        border-radius: 0;
    }

    .project-container-section-divider {
        width:100%;
    }

    .project-dialog-section-header {
        font-size:1.2em;
        border-left:8px solid #7d6de2;
        padding:0 0 0 12px;
    }
    
    .project-dialog-section-subheader {
        font-size:1em;
        margin-top:0.8em;
        border-left:8px solid #7d6de2;
        padding:0 0 0 12px;
    }
    
    .project-dialog-section-tertiary-header {
        font-size:1em;
        margin-top:0.2em;
        padding:0 0 0 20px;
    }
    
    .project-dialog-section-header-support {
        margin-top:-0.7em;
        padding:0 0 0 20px;
    }

    .project-dialog-content-cards {
        gap:1em;
        margin:0 0 1em 0;
        flex-direction: column;
        align-items: center;
    }

    .card, #spacetime-audience .card {
        width:100%;
        box-sizing: border-box;
    }

    #spacetime-litreview-cards .card {
        flex-direction: column-reverse;
        gap:1em;
    }

    .big-small-numbers {
        gap:0.8em;
    }

    .project-dialog-prev-next-buttons {
        position:relative;
        width:91vw;
        font-size: 1.2em;

        display:none;
    }

    .previous-project, .next-project {
        gap:0.5em;
        width:5em;
        opacity:1;
        padding:2em 1em 0.5em;
    }

    .previous-project:hover {
        opacity:1;
    }
    
    .next-project:hover {
        opacity:1;
    }

    dialog::backdrop {
        opacity: 0.95;
    }

    #omni-intro-brands {
        width:100%;
    }

    #omni-research-cards .card {
        flex-direction:column;
        gap:1.2em;
        align-items: center;
        text-align: center;
    }
    
    #omni-methodologies-cards img {
        transform: rotate(90deg);    
    }

    #omni-competitor-apps {
        width:60%;
    }

    #interview-screenshot-image {
        width:100%;
        border-radius:0.4em;
    }

    .omni-scenarios-image-container {
        flex-direction: column;
        gap:1em;
    }
    
    .omni-scenarios-image-container img {
        border-radius:0.4em;
        width:100%;
    }

    .project-dialog-section-content:has(> #omni-wireframes-image) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #omni-wireframes-image {
        border-radius:0.4em;
        width:50em;
        margin:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #omni-mocks) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #omni-mocks {
        width:50em;
    }

    .project-dialog-content-cards {
        font-size:1em;
    }

    .project-dialog-section-content a {
        padding:0.5em 0;
    }

    #iks-outcomes-cards {
        width:100%;
        box-sizing: border-box;
    }

    #iks-litreview-cards .card {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    #lesson-plan-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
        gap:0.8em;
    }

    #lesson-plan-container img {
        transform: rotate(90deg);
    }

    .project-multiline-card-container {
        gap:0;
    }

    .iks-workbook-cards-column {
        gap:0.5em;
    }

    #iks-workbooks-cards {
        gap:0.5em;
    }

    .talamana-column {
        gap:2em;
        padding:1em 0;
    }

    #mannequin-website-img {
        border-radius: 0.4em;
        width: 100%;
    }

    .project-dialog-section-content:has(> #curriculum-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #curriculum-img {
        width:55em;
        padding:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #iks-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #iks-img {
        width:50em;
        padding:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #iks-focussed-review-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #iks-focussed-review-img {
        width:28em;
        padding:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #lesson-plan-structure-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #lesson-plan-structure-img {
        width:60em;
        padding:1em 20px 1em 0;
    }

    #procedural-knowledge {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #procedural-knowledge img {
        width:26em;
        padding:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #iks-exposure-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #iks-exposure-img {
        width:55em;
        padding:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #mannequin-website-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #mannequin-website-img {
        width:40em;
        margin:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #temple-fractal-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #temple-fractal-img {
        width:40em;
        margin:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #printed-temple) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #printed-temple {
        width:60em;
        margin:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #evaluating-workbooks-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #evaluating-workbooks-img {
        width:60em;
        margin:1em 20px 1em 0;
    }

    .project-dialog-section-content:has(> #remote-brainstorming-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
    }

    #remote-brainstorming-img {
        width:50em;
        margin:1em 20px 1em 0;
    }

    .card {
        padding:0.8em;
        border-radius: 0.8em;;
    }

    #project-basic-card-header-explorations {
        justify-content: space-between;
        gap:0;
    }

    .songs-explorations-header-child {
        width:10em;
    }

    .songs-two-in-three-cards {
        width:100%;
        box-sizing: border-box;
    }

    #remove-placement-words {
        justify-content: space-between;
    }

    #remote-explorations-cards .card {
        gap:0.8em;
    }

    .project-dialog-section-content:has(> .full-size-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
        /* box-sizing: border-box; */
    }

    .full-size-img {
        border-radius: 0.4em;
        width:200%;
        margin-right:20px;
    }

    .project-dialog-section-content:has(> #remote-state-of-art-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
        box-sizing: border-box;
    }

    #remote-state-of-art-img {
        width:50em;
    }

    .project-dialog-section-content:has(> #hardware-backend-3d-img) {
        width:100%;
        overflow-x: auto;
        white-space: nowrap;
        box-sizing: content-box;
    }

    #hardware-backend-3d-img {
        width:50em;
        border-radius: 0.4em;
        margin-right:20px;
    }

    #hardware-img-group img {
        border-radius: 0.4em;
    }

    #assigning-emotions-grid-img {
        box-sizing: border-box;
    }

    #assigning-emotions-grid-img img {
        margin:1em 0;
        width:100%;
    }

    #generating-3d-models-cards .card {
        text-align: center;
    }

    .songs-3d-cards .card {
        flex-direction: column;
    }

    .song-3d-card-column {
        width:100%;
    }

    #createnshare-persona {
        width:100%;
        box-sizing: border-box;
    }

    #createnshare-dialog .createnshare-not-full-width {
        width:100%;
        box-sizing: border-box;
    }

    .createnshare-prototypes .card img {
        width:100%;
        margin-left:1em;
    }

    #bonfire-dialog .createnshare-features img {
        width:100%;
    }

    #bonfire-user-group img {
        width:80%;
    }

    #coach-man img {
        width:100%;
    }

    .coach-mocks img {
        width:42em;
    }

    .coach-mocks .card {
        justify-content: flex-start;
        align-items: flex-start;
        padding:0 2em;
        overflow-x: auto;
    }

    .invisible {
        display:none;
    }

    .only-arrow {
        padding:0;
    }

    .only-arrow img {
        transform: rotate(0deg) !important;
        margin:-1em auto;
    }

    #omni-methodologies-cards .rotate-arrow-on-mobile {
        transform: rotate(-90deg);
    }

    .coach-comment-refinement img {
        width:100%;
    }

    #genocorp-character-creation-image img, #genocorp-graph img {
        width:100%;
        border-radius: 0.4em;
    }

    .genocorp-mocks img:first-of-type {
        margin:0;
    }

    .genocorp-mocks img {
        width:100%;
    }

    .also-check-child .also-check-card {
        padding:0 1.5em;
    }

    #also-check-out-anchor #also-check-out-waititshere-a {
        width:100%;
    }

    #architecture-image-container img {
        border-radius: 0;
    }

    .remote-youtube {
        height:20em;
        margin-left:20px;
    }

}
@media (min-width:600px) {
    body {
        font-size:12px;
    }
    dialog {
        width:90vw;
    }
    dialog::backdrop {
        opacity: 0.95;
    }
    .project-dialog-prev-next-buttons {
        position:relative;
        width:89vw;
        font-size: 1.2em;

        display:none;
    }
    .previous-project, .next-project {
        gap:0.5em;
        width:5em;
        opacity:1;
        padding:2em 1em 0.5em;
    }

    .previous-project:hover {
        opacity:1;
    }
    
    .next-project:hover {
        opacity:1;
    }
}
@media (min-width:1000px) {
    body {
        font-size:12px;
    }
    dialog {
        width:70vw;
    }

    .project-dialog-prev-next-buttons {
        position:fixed;
        width:91vw;
        font-size: 1.2em;

        display:flex;
    }
    
    .previous-project, .next-project {
        gap:0.75em;
        width:5em;
        opacity:0.5;
        padding:1.2em;
    }
    
    .previous-project:hover {
        opacity:0.8;
    }
    
    .next-project:hover {
        opacity:0.8;
    }
}
@media (min-width:1350px) {
    body {
        font-size:14px;
    }
    dialog {
        width:60vw;
    }

    .project-dialog-prev-next-buttons {
        position:fixed;
        width:91vw;
        font-size: 1.2em;

        display:flex;

    }
    
    .previous-project, .next-project {
        gap:0.75em;
        width:5em;
        opacity:0.5;
        padding:1.2em;
    }
    
    .previous-project:hover {
        opacity:0.8;
    }
    
    .next-project:hover {
        opacity:0.8;
    }
}
@media (min-width:1700px) {
    body {
        font-size:16px;
    }
    dialog {
        width:60vw;
    }

    .project-dialog-prev-next-buttons {
        position:fixed;
        width:91vw;
        font-size: 1.2em;

        display:flex;

    }
    
    .previous-project, .next-project {
        gap:0.75em;
        width:5em;
        opacity:0.5;
        padding:1.2em;
    }
    
    .previous-project:hover {
        opacity:0.8;
    }
    
    .next-project:hover {
        opacity:0.8;
    }
}
@media (min-width:1850px) {
    body {
        font-size:18px;
    }
    dialog {
        width:60vw;
    }

    .project-dialog-prev-next-buttons {
        position:fixed;
        width:91vw;
        font-size: 1.2em;

        display:flex;

    }
    
    .previous-project, .next-project {
        gap:0.75em;
        width:5em;
        opacity:0.5;
        padding:1.2em;
    }
    
    .previous-project:hover {
        opacity:0.8;
    }
    
    .next-project:hover {
        opacity:0.8;
    }
}
@media (min-width:2000px) {
    body {
        font-size:22px;
    }
    dialog {
        width:60vw;
    }

    .project-dialog-prev-next-buttons {
        position:fixed;
        width:91vw;
        font-size: 1.2em;

        display:flex;

    }
    
    .previous-project, .next-project {
        gap:0.75em;
        width:5em;
        opacity:0.5;
        padding:1.2em;
    }
    
    .previous-project:hover {
        opacity:0.8;
    }
    
    .next-project:hover {
        opacity:0.8;
    }
}